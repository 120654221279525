import { ChainId } from 'constant';
import { computed, makeAutoObservable } from 'mobx';
import Deployed599 from 'constant/deployed-599.json';
import Deployed1088 from 'constant/deployed-1088.json';
import user from './user';
import { ContractsQuery } from 'gql/graph/__generated__/request';
interface ChainContract {
    [ChainId.mainnet]?: ContractsQuery['contracts'];
    [ChainId.testnet]?: ContractsQuery['contracts'];
}

export class Contracts {
    contractsChainId: ChainContract = {
        [ChainId.mainnet]: Deployed1088 as ContractsQuery['contracts'],
        [ChainId.testnet]: undefined,
    };
    constructor() {
        makeAutoObservable<this>(this);
    }

    @computed get contracts() {
        return this.contractsChainId[user.chainId];
    }

    setContract(contracts: ContractsQuery['contracts']) {
        this.contractsChainId[user.chainId] = contracts;
    }
}

export default new Contracts();
