import { ChainId } from '../constant';
import { Client } from './type';

const mainnetGraph = process.env.REACT_APP_MAINNET_GRAPH;

export default {
    [ChainId.mainnet]: {
        [Client.graph]: mainnetGraph || 'https://prod.api.enkixyz.com/',
        [Client.exchangeSubgraph]:
            'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/exchange',
        [Client.distributor]: 'https://fantasyapi.enkixyz.com/',
    },
    [ChainId.testnet]: {
        [Client.graph]: 'https://lsd-server-v2-cezac.ondigitalocean.app/',
        [Client.exchangeSubgraph]:
            'https://metisapi.0xgraph.xyz/subgraphs/name/netswap/exchange',
        [Client.distributor]:
            'https://enki-fantasy-api-test-ilqik.ondigitalocean.app/',
    },
};
