import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

import Config from './config';
import { ChainId, DefaultChainId, SupportChainIDs } from 'constant';
import { getSdk as getGraphSdk } from './graph/__generated__/request';
import { getSdk as getDistributorSdk } from './distributor/__generated__/request';
import { GraphQLClient } from 'graphql-request';
import { Client } from './type';
export function getCurrentChainId(): ChainId {
    const web3ChainId = Number(
        ((window as any)?.web3 as any)?.currentProvider.chainId,
    );

    return SupportChainIDs.indexOf(web3ChainId) >= 0
        ? web3ChainId
        : DefaultChainId;
}

export function getApolloClient(key: string, chainId?: ChainId) {
    console.log('client with chain', chainId || getCurrentChainId());
    return new ApolloClient({
        cache: new InMemoryCache(),
        link: new HttpLink({
            uri: (Config[chainId || getCurrentChainId()] as any)[key],
        }),
    });
}

const sdkWrapper: any = async (
    action: () => Promise<any>,
    operationName: string,
    operationType?: string,
    variables?: any,
): Promise<any> => {
    return action().catch((error) => {
        if (variables?._catchError) {
            console.error(
                'query error, method:',
                operationName,
                variables || '',
            );
        } else {
            throw error;
            // console.log(JSON.stringify(error));
            // setTimeout(() => {
            //   alert.error({
            //     title: 'Error',
            //     text: error?.response?.errors?.length
            //       ? error?.response?.errors[0]?.message
            //       : 'Something went wrong.',
            //   });
            // }, 0);
        }
    });
};

export function getGraphRequestApi(chainId?: ChainId) {
    return getGraphSdk(
        new GraphQLClient(
            (Config[chainId || getCurrentChainId()] as any)[Client.graph],
        ),
        sdkWrapper,
    );
}

export function getDistributorApi(chainId?: ChainId) {
    return getDistributorSdk(
        new GraphQLClient(
            (Config[chainId || getCurrentChainId()] as any)[Client.distributor],
        ),
        sdkWrapper,
    );
}
