import { EMetisSymbol, MetisSymbol } from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
import Modal from 'components/Modal/modal';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Transfer } from 'assets/images/transfer.svg';
import { TYPE } from 'theme';
import { RowBetween } from 'components/Row';
import Button from 'components/Button';
import { RedemptionQueueItem } from 'gql/graph/__generated__/request';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import user from 'stores/user';
import useRedemptionQueueMethods from 'hooks/useRedemptionQueueMethods';
import useSend from 'hooks/useSend';
import redemptionQueue from 'stores/redemptionQueue';
import { formatHashValue } from 'utils';
import { useConstantToken } from 'hooks/useConstantToken';
import { useMetis } from 'hooks/useContract';
import simpleDialog from 'stores/simpleDialog';

const StyledModal = styled(Modal)`
    .modalContent {
        padding: 0;
    }
`;

const Wrapper = styled.div``;

const ImgWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background-image: url(${require('assets/images/redeemModalBg.png')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120%;
    padding: 40px 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 10px;
    `};

    .imgItem {
        svg {
            width: 100px;
            height: 100px;
        }
    }
`;

const StyledTransfer = styled(Transfer)`
    width: 40px;
    height: 40px;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
    padding: 40px 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 10px;
    `};
`;

const ButtonGropup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
    button {
        min-width: 180px !important;
    }
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    redemptionQueueItem: RedemptionQueueItem;
    updateNFTInformation: () => Promise<void>;
}

export default function RedeemModal({
    isOpen,
    onClose,
    redemptionQueueItem,
    updateNFTInformation,
}: Props) {
    const { redemptionQueueContract } = useRedemptionQueueMethods();
    const metisContract = useMetis();

    const send = useSend();

    async function redeemRedemptionTicketNft() {
        console.log(redemptionQueueContract, metisContract);
        if (!redemptionQueueContract || !metisContract) return;
        const redemptionQueueContractBalance = await metisContract.balanceOf(
            redemptionQueueContract.address,
        );

        if (
            new BigNumber(redemptionQueueItem.amount).isGreaterThan(
                redemptionQueueContractBalance.toString(),
            )
        ) {
            simpleDialog.error({
                title: 'Warning',
                text: `Apologies, we currently have insufficient Metis tokens for redemption. We will process your request as soon as possible. Please try again later.`,
            });
            return;
        }
        await send({
            contract: redemptionQueueContract,
            method: 'redeemRedemptionTicketNft',
            params: [redemptionQueueItem.id, redemptionQueueItem.holder],
            pendingText: `Redeeming the ${new BigNumber(
                redemptionQueueItem.amount,
            )
                .shiftedBy(-18)
                .toFixed()} Metis of the redemption #${redemptionQueueItem.id}`,
            summary: `Redeemed the ${new BigNumber(redemptionQueueItem.amount)
                .shiftedBy(-18)
                .toFixed()} Metis of redemption #${redemptionQueueItem.id} `,
            onSuccess: async () => {
                await redemptionQueue.getNftList();
                await updateNFTInformation();
                onClose();
            },
        });
    }
    return (
        <StyledModal isOpen={isOpen} onClose={onClose}>
            <Wrapper>
                <ImgWrapper>
                    <AutoColumn className="imgItem" justify="center" gap="10px">
                        <EMetisSymbol />
                        <TYPE.main fontSize={16} fontWeight={600}>
                            {new BigNumber(redemptionQueueItem.amount)
                                .shiftedBy(-18)
                                .toFixed()}{' '}
                            eMetis
                        </TYPE.main>
                    </AutoColumn>
                    <StyledTransfer />
                    <AutoColumn className="imgItem" justify="center" gap="10px">
                        <MetisSymbol />
                        <TYPE.main fontSize={16} fontWeight={600}>
                            {new BigNumber(redemptionQueueItem.amount)
                                .shiftedBy(-18)
                                .toFixed()}{' '}
                            Metis
                        </TYPE.main>
                    </AutoColumn>
                </ImgWrapper>
                <Content>
                    <RowBetween>
                        <TYPE.main fontSize={14}>FEE | 0.00%</TYPE.main>
                        <TYPE.main fontSize={14}>(0.00 Metis)</TYPE.main>
                    </RowBetween>
                    <RowBetween>
                        <TYPE.main fontSize={14}>REDEEMABLE DATE</TYPE.main>
                        <TYPE.main fontSize={14}>
                            {moment(
                                Number(redemptionQueueItem.maturity) * 1000,
                            ).format('MMM DD, YYYY, HH:MM LT  Z')}
                        </TYPE.main>
                    </RowBetween>
                    <RowBetween>
                        <TYPE.main fontSize={14}>RECIPIENT</TYPE.main>
                        <TYPE.main fontSize={14}>
                            {formatHashValue(redemptionQueueItem.holder)}{' '}
                            {redemptionQueueItem.holder.toLowerCase() ===
                            user.address.toLowerCase()
                                ? '(you)'
                                : ''}
                        </TYPE.main>
                    </RowBetween>
                    <ButtonGropup>
                        <Button type="outlineWhite" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button onClick={redeemRedemptionTicketNft}>
                            Confirm
                        </Button>
                    </ButtonGropup>
                </Content>
            </Wrapper>
        </StyledModal>
    );
}
