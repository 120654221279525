import { metisSepolia } from 'common-helpers/metisSepoliachain';
import { Chain, mainnet, metis } from 'wagmi/chains';
export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

export enum ChainId {
    testnet = 59902,
    mainnet = 1088,
}

export const MainnetOnly = Number(process.env.REACT_APP_MAINNET_ONLY) === 1;

export const DefaultChainId = MainnetOnly ? ChainId.mainnet : ChainId.testnet;

export const SupportChainIDs = MainnetOnly
    ? [ChainId.mainnet]
    : [ChainId.mainnet, ChainId.testnet];

export const ProviderSupportChainId: Chain[] = [metisSepolia];

export const NETWORK_CONFIG = {
    [ChainId.testnet]: {
        name: 'Metis Sepolia Testnet',
        scanLable: 'Metis(Sepolia)-explore',
        explore: 'https://sepolia-explorer.metisdevops.link/',
    },
    [ChainId.mainnet]: {
        name: 'Andromeda',
        scanLable: 'Andromeda-explorer',
        explore: 'https://andromeda-explorer.metis.io/',
    },
};

export const SWITCH_NETWORK_CONFIG = {
    [ChainId.mainnet]: {
        chainId: `0x${ChainId.mainnet.toString(16)}`,
        chainName: 'Andromeda',
        nativeCurrency: {
            name: 'Metis Token',
            symbol: 'METIS',
            decimals: 18,
        },
        rpcUrls: [`https://andromeda.metis.io/?owner=${ChainId.mainnet}`],
        blockExplorerUrls: ['https://andromeda-explorer.metis.io'],
    },
    [ChainId.testnet]: {
        chainId: `0x${ChainId.testnet.toString(16)}`,
        chainName: 'Metis Sepolia',
        nativeCurrency: {
            name: 'Metis Token',
            symbol: 'METIS',
            decimals: 18,
        },
        rpcUrls: [
            'https://sepolia.metisdevops.link',
            // `https://stardust.metis.io/?owner=${ChainId.layer2Testnet}`,
        ],
        blockExplorerUrls: ['https://sepolia-explorer.metisdevops.link'],
    },
};

export const RPC = {
    [ChainId.mainnet]: 'https://andromeda-rpc.metis.io',
    [ChainId.testnet]: 'https://sepolia.metisdevops.link',
};

export type Token = {
    name: string;
    symbol: string;
    decimals: number;
    address: string;
    img?: string;
};

export enum FaucetToken {
    metis = 'metis',
    enki = 'enki',
}

export const LockLPConfig = [
    {
        logo: require('assets/images/platform/netswap.png'),
        platform: 'Netswap',
        token: {
            [ChainId.mainnet]: {
                name: 'NLP',
                symbol: 'NLP',
                decimals: 18,
                address: '0x92372dc7425c4b6a05ff5aae791333de750ae9ed',
            },
            [ChainId.testnet]: {
                name: 'NLP',
                symbol: 'NLP',
                decimals: 18,
                address: '0x73902A13c97AFB4e6F62Ea0382C5BC323E734E6A',
            },
        },
        lpName: 'ENKI - Metis',
        type: 'lp',
        lpLink: 'https://netswap.io/#/add/0x096a84536ab84e68ee210561ffd3a038e79736f1/0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000',
    },
    {
        logo: require('assets/images/platform/hercules.png'),
        platform: 'Hercules',
        lpName: 'ENKI - m.USDC',
        type: 'lp',
        lpLink: 'https://app.hercules.exchange/liquidity/?token1=0x096a84536ab84e68ee210561ffd3a038e79736f1&token2=0xEA32A96608495e54156Ae48931A7c20f0dcc1a21&type=v2&position=lp',
        token: {
            [ChainId.mainnet]: {
                name: 'CMLT-LP',
                symbol: 'CMLT-LP',
                decimals: 18,
                address: '0xCa9e2B800E77eC61B54c50F842DC4aDa260bB8B4',
            },
            [ChainId.testnet]: {
                name: 'CMLT-LP',
                symbol: 'CMLT-LP',
                decimals: 18,
                address: '0xe4bc3fe146c7e1b72a14355f6bcb699263ee95e0',
            },
        },
    },
];
export type LockLP = (typeof LockLPConfig)[0];
