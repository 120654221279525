import { ChainId } from 'constant';
import { computed, makeAutoObservable } from 'mobx';
import storage from 'utils/storage';
import user from './user';
import contracts from './contracts';

interface TokensPrice {
    [key: string]: {
        price: number;
    };
}

const tokenPrice = storage.get('enki_token_price_withchain');
export class TokenInfo {
    tokenPriceWithChain: {
        [ChainId.mainnet]: TokensPrice;
        [ChainId.testnet]: TokensPrice;
    } = tokenPrice || {
        [ChainId.mainnet]: {},
        [ChainId.testnet]: {},
    };
    fetchTokenList: string[] = [];

    constructor() {
        makeAutoObservable<this>(this);
    }

    @computed get tokenPrice() {
        const ts = this.tokenPriceWithChain[user.chainId];
        const enkiAddress = contracts.contracts?.enki.toLowerCase();
        const eMetisAddress = contracts.contracts?.eMetis.toLowerCase();
        const seMetisAddress = contracts.contracts?.seMetis.toLowerCase();
        const metisAddress = contracts.contracts?.l2Metis.toLowerCase();
        if (ts) {
            if (enkiAddress && ts[enkiAddress]) {
                ts['enki'] = ts[enkiAddress];
            }
            if (eMetisAddress && ts[eMetisAddress]) {
                ts['eMetis'] = ts[eMetisAddress];
            }
            if (seMetisAddress && ts[seMetisAddress]) {
                ts['seMetis'] = ts[seMetisAddress];
            }
            if (metisAddress && ts[metisAddress]) {
                ts['metis'] = ts[metisAddress];
            }
        }

        return ts;
    }

    setTokensPrice(tokensPrice: TokensPrice) {
        this.tokenPriceWithChain[user.chainId] = {
            ...this.tokenPrice,
            ...tokensPrice,
        };
        storage.set('enki_token_price_withchain', this.tokenPriceWithChain);
    }

    addFetchTokens(tokens: string[]) {
        this.fetchTokenList = [
            ...Array.from(new Set(this.fetchTokenList.concat(tokens))),
        ];
    }
}

export default new TokenInfo();
