import React from 'react';
import styled from 'styled-components';
import Row, { RowBetween } from '../Row';
import {
    Discord,
    Logo,
    EnkiSymbol,
    Medium,
    Telegram,
    twitter,
} from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
import { Link, TYPE } from 'theme';
import TooltipInfo from 'components/TooltipInfo';
// import Modal from 'utils/components/modal';

const FooterWrapper = styled.div`
    width: 100%;
    padding: 36px 120px;
    background-color: #000;

    a {
        text-decoration: none;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 40px 20px;
    padding-bottom: 60px;
    `};
`;
const LogoSVG = styled(Logo)`
    width: 120px;
    height: 40px;
`;

const MediaWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    flex-direction: row;
`;

const MediaImg = styled.img`
    width: 40px;
    height: 40px;
`;

const MediaInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    `};
`;

const StyledTopOuterLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 12px;
    `};
`;

const OuterLink = styled.a`
    color: #dba45c !important;
    font-size: 16px;
    cursor: pointer;
`;

const StyledMediaWrapperAutoColumn = styled(AutoColumn)`
    padding: 40px 0;
    gap: 14px;
`;

const BottonRow = styled(Row)`
    justify-content: center;
    gap: 48px;
    padding-bottom: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 12px;
    flex-direction: column;
    `};
`;
export default function Footer({ className }: { className?: string }) {
    return (
        <FooterWrapper className={className}>
            <MediaInfo>
                <Row>
                    <LogoSVG />
                </Row>
                <StyledTopOuterLinkWrapper>
                    <OuterLink
                        href="https://drive.google.com/file/d/1Oy9tiT4b4r4pZJhI_6t6i-6p6Q052M0E/view"
                        target="_blank"
                    >
                        Litepaper
                    </OuterLink>

                    <OuterLink href="/fantasy" target="_blank">
                        Fantasy
                    </OuterLink>
                    <OuterLink href="https://docs.enkixyz.com" target="_blank">
                        Docs
                    </OuterLink>
                </StyledTopOuterLinkWrapper>
            </MediaInfo>
            <StyledMediaWrapperAutoColumn>
                <MediaWrapper>
                    <a
                        href="https://twitter.com/ENKIProtocol"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg
                            src={require('assets/images/media/ic_Twitter.png')}
                        />
                    </a>
                    <a
                        href="https://t.me/enkixyz"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg
                            src={require('assets/images/media/ic_telegram.png')}
                        />
                    </a>
                    <a
                        href="https://medium.com/@ENKIProtocol"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg
                            src={require('assets/images/media/ic_medium.png')}
                        />
                    </a>
                    <a
                        href="https://github.com/ENKIXYZ"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg
                            src={require('assets/images/media/ic_github.png')}
                        />
                    </a>
                    {/* <a
                        href="https://medium.com/@ENKIProtocol"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImg src={Discord} />
                    </a> */}
                </MediaWrapper>
                <TYPE.main
                    textAlign={'center'}
                    color="#fff"
                    fontSize={14}
                    fontWeight={600}
                >
                    enkixyz.com
                </TYPE.main>
            </StyledMediaWrapperAutoColumn>
            <BottonRow>
                <TYPE.main fontSize={14} color="#fff">
                    © 2024 · All rights reserved
                </TYPE.main>
                <a
                    href="https://docs.google.com/document/d/e/2PACX-1vRhHUEkdBseZ7DDSmMdq7MCQJBxlpz8oEd6XigPHXWN-vM8lUOkWW9SlWzVD-Z0FhsN7CMLCban1i4y/pub"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TYPE.main fontSize={14} color="#fff">
                        Privacy Policy
                    </TYPE.main>
                </a>
                <a
                    href="https://docs.google.com/document/d/e/2PACX-1vQ_HAla5Is8bQhIexsQS_oCJN-siDE7N3gkaSN_CV5pjVLLBODBCVcPU5JKGNuc869QmKCem4YK5B0b/pub"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TYPE.main fontSize={14} color="#fff">
                        Terms of Service
                    </TYPE.main>
                </a>
            </BottonRow>
        </FooterWrapper>
    );
}
