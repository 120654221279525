import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
// import style from './index.module.scss';
import { ChainId } from '../../constant';
import useWeb3SwitchNetwork from 'hooks/useSwitchNetwork';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import user from 'stores/user';
import { observer } from 'mobx-react';
export const SelectNetworkGlobalStyle = createGlobalStyle`
    .selectNetworkPaper{
        /* transform: translateX(10px) !important; */
        ul {
            padding: 0 !important;
            background: ${({ theme }) => theme.c.bg01};

            li {
                color: ${({ theme }) => theme.c.t01};
                &.Mui-selected {
                    background: transparent !important;
                }

                &:hover {
                    background: ${({ theme }) => theme.c.bg02} !important;
                }
            }
        }
    }
`;

const StyledArrowDropDownIcon = styled(KeyboardArrowDownIcon)`
    position: absolute;
    width: 20px !important;
    height: 20px !important;
    font-size: 20px !important;
    color: '#0e0905';
    z-index: 0;
    right: 4px;
    top: 50%;
    transform: translateY(-38%);
`;

const CustomeMemuItem = styled(MenuItem)`
    color: #000;
    padding-right: 0rem !important;
    min-height: unset;
    width: 130px;
`;
const StyledSelect = styled(Select)`
    position: relative;
    background: transparent;
    // padding: 8px;
    // // padding-right: 24px;
    border-radius: 16px;
    margin-right: 12px;
    font-size: 14px;
    width: 130px;
    height: 40px;
    border: 1px solid #0e0905;

    color: ${({ theme }) => theme.c.t01};

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 0;
    `}

    .MuiSelect-select {
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 10px 8px 12px 12px !important;
        box-sizing: border-box;
        text-align: left;
    }
    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }
`;

// const useStyles = makeStyles({
//     root: {
//         background: '#393d36',
//         color: '#EBBA4A',
//         borderRadius: '8px',
//         '&:focus': {
//             background: '#393d36',
//             borderRadius: '8px',
//         },
//         paddingRight: '0px',
//     },
//     select: {
//         color: '#EBBA4A',
//     },
//     menuRoot: {
//         background: '#0d1f32',
//     },
//     menuItem: {
//         background: '#93d36',
//         color: '#63d7cc',
//         '&.Mui-selected': {
//             background: 'transparent !important',
//             color: '#EBBA4A !important',
//         },
//         '&:hover': {
//             background: 'transparent !important',
//             color: '#EBBA4A !important',
//         },
//     },
// });
const IconComponent = () => {
    return <StyledArrowDropDownIcon />;
};
function NetworkSelect() {
    const { handleChangeNetwork } = useWeb3SwitchNetwork();

    // moves the menu below the select input
    const menuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        },
        classes: {
            paper: 'selectNetworkPaper',
        },
    };

    async function changeEnv(chainId: ChainId) {
        const success = await handleChangeNetwork(chainId);
        if (success) {
            user.setChainId(chainId);
        }
    }

    return (
        <>
            <SelectNetworkGlobalStyle />
            <StyledSelect
                disableUnderline
                MenuProps={menuProps as MenuProps}
                IconComponent={IconComponent}
                // className={style.wrapper}
                value={user.chainId}
            >
                <CustomeMemuItem
                    key={ChainId.mainnet}
                    value={ChainId.mainnet}
                    classes={
                        {
                            // root: cx(selectStyles.menuItem, style.menuItem),
                        }
                    }
                    onClick={() => {
                        changeEnv(ChainId.mainnet);
                    }}
                >
                    Andromeda
                    {/* {NETWORK_CONFIG[Env.mainnet][Layer.layer2].name} */}
                </CustomeMemuItem>
                <CustomeMemuItem
                    key={ChainId.testnet}
                    value={ChainId.testnet}
                    classes={
                        {
                            // root: cx(selectStyles.menuItem, style.menuItem),
                        }
                    }
                    onClick={() => {
                        changeEnv(ChainId.testnet);
                    }}
                >
                    Metis(Sepolia)
                    {/* {NETWORK_CONFIG[Env.test][Layer.layer2].name} */}
                </CustomeMemuItem>
            </StyledSelect>
        </>
    );
}

export default observer(NetworkSelect);
