import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import styled from 'styled-components';
// import style from './index.module.scss';

const StyledCircularProgress = styled(CircularProgress)`
    color: #000;
`;

interface Props {
    size?: number;
    style?: React.CSSProperties;
    className?: string;
}

export default function Loading({
    size = 24,
    style: cssStyle,
    className,
}: Props) {
    return (
        <StyledCircularProgress
            style={cssStyle}
            size={size}
            className={className}
        />
    );
}
