import { Fade, Menu } from '@mui/material';
import {
    EMetisSymbol,
    EnkiSymbol,
    MetisSymbol,
    SeMetisSymbol,
} from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
import { NETWORK_CONFIG } from 'constant';
import { useConstantToken } from 'hooks/useConstantToken';
import useTokenBalance from 'hooks/useTokenBalance';
import { observer } from 'mobx-react-lite';
import React from 'react';
import user from 'stores/user';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { formatNumber } from 'utils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as OffSVG } from 'assets/images/off.svg';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useDisconnect } from 'wagmi';
import { useAccountModal } from '@rainbow-me/rainbowkit';
import WalletIcon from '@mui/icons-material/Wallet';
import ShareIcon from '@mui/icons-material/Share';
import AddTokenToWallet from 'components/AddTokenToWallet';
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

interface Props {
    children: React.ReactNode;
}

const StyledMenu = styled(Menu)`
    border-radius: 12px;
    .MuiPaper-root {
        transform: translate(-20px, 20px);
    }
    .MuiList-root {
        padding: 0;
    }
`;

const MenuContent = styled.div`
    width: 220px;
    padding: 14px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    background: #000000;
`;

const MenuRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    cursor: pointer;
    width: 100%;

    img,
    svg {
        width: 24px;
        height: 24px;
    }

    span {
        color: #0d0102;
        font-size: 14px;
    }
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
    color: #c3b9b9;
`;

const StyledOff = styled(OffSVG)`
    color: red;
`;

const StyledEmojiEventsIcon = styled(EmojiEventsIcon)`
    color: #dca45c;
`;

const StyledShareIcon = styled(ShareIcon)`
    color: #dca45c;
`;

const StyledWalletIcon = styled(WalletIcon)`
    color: #c3b9b9;
`;

const StyledCenterColumn = styled(AutoColumn)`
    flex-grow: 1;
`;

function WalletDropMenu({ children }: Props) {
    const { disconnect } = useDisconnect();
    const { openAccountModal } = useAccountModal();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { metis, seMetis, eMetis, enki } = useConstantToken();

    const { balance: metisBalance } = useTokenBalance(metis.address);
    const { balance: eMetisBalance } = useTokenBalance(eMetis.address);
    const { balance: seMetisBalance } = useTokenBalance(seMetis.address);
    const { balance: enkiBalance } = useTokenBalance(enki.address);
    const isOpenMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleChangeAccount() {
        await disconnect();
        user.clearLoginInfo();
        handleClose();
        user.toggleConnectModal();
    }

    return (
        <>
            <Wrapper onClick={handleClick}>{children}</Wrapper>
            <StyledMenu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={isOpenMenu}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuContent>
                    <MenuRow>
                        <MetisSymbol />
                        <StyledCenterColumn>
                            <TYPE.main
                                fontSize={14}
                                fontWeight={600}
                                color={'#DCA45C'}
                            >
                                {formatNumber(metisBalance)} Metis
                            </TYPE.main>
                            <TYPE.main fontSize={12} color="#DCA45C">
                                Metis Balance
                            </TYPE.main>
                        </StyledCenterColumn>
                    </MenuRow>
                    <MenuRow>
                        <EMetisSymbol />
                        <StyledCenterColumn>
                            <TYPE.main
                                fontSize={14}
                                fontWeight={600}
                                color={'#DCA45C'}
                            >
                                {formatNumber(eMetisBalance)} eMetis
                            </TYPE.main>
                            <TYPE.main fontSize={12} color="#DCA45C">
                                eMetis Balance
                            </TYPE.main>
                        </StyledCenterColumn>
                        <AddTokenToWallet token={eMetis} />
                    </MenuRow>
                    <MenuRow>
                        <SeMetisSymbol />
                        <StyledCenterColumn>
                            <TYPE.main
                                fontSize={14}
                                fontWeight={600}
                                color={'#DCA45C'}
                            >
                                {formatNumber(seMetisBalance)} seMetis
                            </TYPE.main>
                            <TYPE.main fontSize={12} color="#DCA45C">
                                seMetis Balance
                            </TYPE.main>
                        </StyledCenterColumn>
                        <AddTokenToWallet token={seMetis} />
                    </MenuRow>
                    <MenuRow>
                        <EnkiSymbol />
                        <StyledCenterColumn>
                            <TYPE.main
                                fontSize={14}
                                fontWeight={600}
                                color={'#DCA45C'}
                            >
                                {formatNumber(enkiBalance)} ENKI
                            </TYPE.main>
                            <TYPE.main fontSize={12} color="#DCA45C">
                                ENKI Balance
                            </TYPE.main>
                        </StyledCenterColumn>
                        <AddTokenToWallet token={enki} />
                    </MenuRow>
                    <MenuRow>
                        <StyledEmojiEventsIcon />
                        <StyledCenterColumn>
                            <TYPE.main
                                fontSize={14}
                                fontWeight={600}
                                color={'#DCA45C'}
                            >
                                {user.stakingPoints.toFixed(4)}
                            </TYPE.main>
                            <TYPE.main fontSize={12} color="#DCA45C">
                                Staking Points
                            </TYPE.main>
                        </StyledCenterColumn>
                    </MenuRow>
                    <MenuRow>
                        <StyledShareIcon />
                        <StyledCenterColumn>
                            <TYPE.main
                                fontSize={14}
                                fontWeight={600}
                                color={'#DCA45C'}
                            >
                                {Number(user.referrals.points).toFixed(4)}
                            </TYPE.main>
                            <TYPE.main fontSize={12} color="#DCA45C">
                                Referrals Points
                            </TYPE.main>
                        </StyledCenterColumn>
                    </MenuRow>
                    <MenuRow onClick={openAccountModal}>
                        <StyledWalletIcon />
                        <TYPE.main fontSize={12} color="#fff">
                            Account
                        </TYPE.main>
                    </MenuRow>

                    <MenuRow onClick={handleChangeAccount}>
                        <StyledOff />
                        <TYPE.main fontSize={12} color="#fff">
                            Disconnect Wallet
                        </TYPE.main>
                    </MenuRow>
                </MenuContent>
            </StyledMenu>
        </>
    );
}

export default observer(WalletDropMenu);
