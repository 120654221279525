import { useCallback, useMemo } from 'react';
import user from 'stores/user';
import { useEthersProvider } from './useEthersProvider';
import { getContract } from 'utils';
import { Contract } from 'ethers';
import EMetisMinterABI from 'contracts/eMetisMinter/abi.json';
import SeMetisABI from 'contracts/seMetis/abi.json';
import VestingABI from 'contracts/vesting/abi.json';
import ERC20ABI from 'contracts/erc20.json';
import EMetisABI from 'contracts/eMetis.json';
import InviterNFTABI from 'contracts/inviterNFT/abi.json';
import RedemptionQueueABI from 'contracts/redemptionQueue/abi.json';
import contracts from 'stores/contracts';
import ConfigABI from 'contracts/config/abi.json';
import { useAccount, useChainId } from 'wagmi';
import { ChainId, DefaultChainId } from 'constant';

export function useContract(
    address: string | undefined,
    ABI: any,
    withSignerIfPossible = true,
): Contract | null {
    const chainId = useChainId();
    const { address: account } = useAccount();
    const provider = useEthersProvider({
        chainId: chainId || DefaultChainId,
        account,
    });

    return useMemo(() => {
        if (!address || !ABI || !provider) return null;
        try {
            return getContract(
                address,
                ABI,
                provider as any,
                withSignerIfPossible && account ? account : undefined,
            );
        } catch (error) {
            console.error('Failed to get contract', error);
            console.error(address);
            return null;
        }
    }, [address, ABI, provider, withSignerIfPossible, account]);
}

export function useGetContract() {
    const chainId = useChainId();
    const { address: account } = useAccount();
    const provider = useEthersProvider({
        chainId: chainId || DefaultChainId,
        account,
    });

    return useCallback(
        (address: string, ABI: any) => {
            return getContract(
                address,
                ABI,
                provider as any,
                account ? account : undefined,
            );
        },
        [provider, account],
    );
}
export function useGetContractByChainId() {
    const { address: account } = user;
    const chainId = useChainId();
    const { address } = useAccount();
    const provider = useEthersProvider({
        account: address,
        chainId: chainId || DefaultChainId,
    });
    return useCallback(
        (address: string, ABI: any) => {
            if (!address || !ABI || !provider) return null;
            return getContract(address, ABI, provider as any, account);
        },
        [account, provider],
    );
}

export function useEMetis() {
    return useContract(contracts.contracts?.eMetis, ERC20ABI);
}

export function useMetis() {
    return useContract(contracts.contracts?.l2Metis, ERC20ABI);
}

export function useENKI() {
    return useContract(contracts.contracts?.enki, ERC20ABI);
}

export function useSeMetis() {
    return useContract(contracts.contracts?.seMetis, ERC20ABI);
}

export function useEMetisMinter() {
    return useContract(contracts.contracts?.eMetisMinter, EMetisMinterABI);
}

export function useSeMetisVault() {
    return useContract(contracts.contracts?.seMetis, SeMetisABI);
}

export function useVesting() {
    return useContract(contracts.contracts?.vesting, VestingABI);
}

export function useConfig() {
    return useContract(contracts.contracts?.config, ConfigABI);
}

export function useInviterNFT() {
    return useContract(contracts.contracts?.inviterNFT, InviterNFTABI);
}

export function useRedemptionQueue() {
    return useContract(
        contracts.contracts?.redemptionQueue,
        RedemptionQueueABI,
    );
}
