import { AutoColumn } from 'components/Column';
import React from 'react';
import styled from 'styled-components';
import { Link, LinkProps, TYPE } from 'theme';

interface ChildrenProps {
    children: React.ReactNode;
}

export function DataTitle(props: ChildrenProps) {
    return (
        <TYPE.main
            fontSize={20}
            color={'#DCA45C'}
            fontWeight={600}
            textAlign={'center'}
        >
            {props.children}
        </TYPE.main>
    );
}

export function DataDesc(props: ChildrenProps) {
    return (
        <TYPE.main
            fontSize={14}
            color={'#DCA45C'}
            textAlign={'center'}
            className="data-desc"
            style={{ display: 'flex' }}
        >
            {props.children}
        </TYPE.main>
    );
}

const StyledAutoColumn = styled(AutoColumn)`
    gap: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.leftBorder {
        border-left: 1px dashed #8c6437;
    }
`;

interface DataColumnProps extends ChildrenProps {
    leftBorder?: boolean;
}

export function DataColumn(props: DataColumnProps) {
    return (
        <StyledAutoColumn className={props.leftBorder ? 'leftBorder' : ''}>
            {props.children}
        </StyledAutoColumn>
    );
}

const StyledLink = styled(Link)`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .data-desc {
        color: #dca45c !important;
    }
`;

const LinkIcon = styled.img`
    width: 14px;
    height: 14px;
`;

interface DataLinkProps extends LinkProps {
    showLinkIcon?: boolean;
}

export function DataLink({
    children,
    showLinkIcon = true,
    ...props
}: DataLinkProps) {
    return (
        <StyledLink {...props}>
            {children}{' '}
            {showLinkIcon && (
                <LinkIcon src={require('assets/images/link.png')} />
            )}
        </StyledLink>
    );
}

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    gap: 16px;
    margin-top: 20px;
    img {
        width: 53px;
        height: 40px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
    `};
`;

export function ContentTitle(props: ChildrenProps) {
    return (
        <TitleRow>
            <img src={require('assets/images/ic_page_title.png')} alt="" />
            <TYPE.main
                fontSize={40}
                mobileFz={24}
                fontWeight={600}
                color={'#DCA45C'}
                className="titleText"
            >
                {props.children}
            </TYPE.main>
        </TitleRow>
    );
}
