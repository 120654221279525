import { EMetisSymbol, MetisSymbol } from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
import Modal from 'components/Modal/modal';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Transfer } from 'assets/images/transfer.svg';
import { TYPE } from 'theme';
import { RowBetween } from 'components/Row';
import Button from 'components/Button';
import { RedemptionQueueItem } from 'gql/graph/__generated__/request';
import useRedemptionQueueMethods from 'hooks/useRedemptionQueueMethods';
import useSend from 'hooks/useSend';
import BigNumber from 'bignumber.js';
import redemptionQueue from 'stores/redemptionQueue';

const StyledModal = styled(Modal)`
    .modalContent {
        padding: 0;

        width: 480px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        `};
    }
`;

const Wrapper = styled.div``;

const ImgWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background-image: url(${require('assets/images/redeemModalBg.png')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120%;
    padding: 40px 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 10px;
    `};

    .imgItem {
        svg {
            width: 100px;
            height: 100px;
        }
    }
`;

const StyledTransfer = styled(Transfer)`
    width: 40px;
    height: 40px;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    padding: 40px 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 10px;
    `};
`;

const ButtonGropup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
    button {
        min-width: 180px !important;
    }
`;

const Border = styled.div`
    border-top: 1px dashed #dca45c;
    height: 1px;
    width: 100%;
`;
interface Props {
    isOpen: boolean;
    onClose: () => void;
    redemptionQueueItem: RedemptionQueueItem;
    updateNFTInformation: () => Promise<void>;
}

export default function CancelRedeemModal({
    isOpen,
    onClose,
    redemptionQueueItem,
    updateNFTInformation,
}: Props) {
    const { redemptionQueueContract } = useRedemptionQueueMethods();
    const send = useSend();

    async function cancelRedemptionTicketNft() {
        if (!redemptionQueueContract) return;

        await send({
            contract: redemptionQueueContract,
            method: 'cancelRedemptionTicketNft',
            params: [redemptionQueueItem.holder, redemptionQueueItem.id],
            pendingText: `The ${new BigNumber(redemptionQueueItem.amount)
                .shiftedBy(-18)
                .toFixed()} Metis redemption #${
                redemptionQueueItem.id
            } is cancelling`,
            summary: `The ${new BigNumber(redemptionQueueItem.amount)
                .shiftedBy(-18)
                .toFixed()} Metis redemption #${
                redemptionQueueItem.id
            } has been canceled`,
            onSuccess: async () => {
                await redemptionQueue.getNftList();
                onClose();
            },
        });
    }
    return (
        <StyledModal isOpen={isOpen} onClose={onClose}>
            <Wrapper>
                <ImgWrapper>
                    <AutoColumn className="imgItem" justify="center" gap="10px">
                        <TYPE.main fontSize={20} fontWeight={600}>
                            Cancel Redeem
                        </TYPE.main>
                        <EMetisSymbol />
                        <TYPE.main fontSize={16} fontWeight={600}>
                            {new BigNumber(redemptionQueueItem.amount)
                                .shiftedBy(-18)
                                .toFixed()}{' '}
                            eMetis
                        </TYPE.main>
                    </AutoColumn>
                </ImgWrapper>
                <Content>
                    <TYPE.main fontSize={14} textAlign={'center'}>
                        {`Upon cancellation, you will immediately receive ${new BigNumber(
                            redemptionQueueItem.amount,
                        )
                            .shiftedBy(-18)
                            .multipliedBy(
                                0.995,
                            )} eMetis back, minus the cancellation fee.`}
                    </TYPE.main>
                    <Border />
                    <RowBetween>
                        <TYPE.main fontSize={14}>FEE | 0.50%</TYPE.main>
                        <TYPE.main fontSize={14}>
                            {new BigNumber(redemptionQueueItem.amount)
                                .shiftedBy(-18)
                                .multipliedBy(0.005)
                                .toFixed()}{' '}
                            Metis
                        </TYPE.main>
                    </RowBetween>

                    <ButtonGropup>
                        <Button onClick={cancelRedemptionTicketNft}>
                            Confirm
                        </Button>
                    </ButtonGropup>
                </Content>
            </Wrapper>
        </StyledModal>
    );
}
