import { ChainId } from 'constant';
import { action, computed, makeAutoObservable } from 'mobx';
import user from './user';
import storage from 'utils/storage';
import { RedemptionQueueItem } from 'gql/graph/__generated__/request';
import { getGraphRequestApi } from 'gql';
import BigNumber from 'bignumber.js';
interface NftLists {
    [ChainId.mainnet]?: {
        [key: string]: RedemptionQueueItem[];
    };
    [ChainId.testnet]?: {
        [key: string]: RedemptionQueueItem[];
    };
}

const StorageKey = 'enki-nft-lists';

interface LPPrice {
    [key: string]: BigNumber;
}

export class RedemptionQueue {
    nftLists: NftLists = storage.get(StorageKey) || {
        [ChainId.mainnet]: {},
        [ChainId.testnet]: {},
    };
    isLoadingList: boolean = false;
    lpPrice: LPPrice = {};

    constructor() {
        makeAutoObservable<this>(this);
    }

    @computed get redemptionQueueItems(): RedemptionQueueItem[] {
        return user.connected &&
            user?.chainId &&
            this.nftLists &&
            this.nftLists[user.chainId]
            ? (this.nftLists[user.chainId] as any)[
                  user.address.toLowerCase()
              ] || []
            : [];
    }

    @action
    async getNftList() {
        if (!user.address) return;
        this.isLoadingList = true;
        try {
            const res = await getGraphRequestApi().redemptionQueueItems({
                user: user.address,
            });

            if (res.redemptionQueueItems) {
                const res = await getGraphRequestApi().redemptionQueueItems({
                    user: user.address,
                });
                (this.nftLists[user.chainId] as any)[
                    user.address.toLowerCase()
                ] =
                    res?.redemptionQueueItems?.sort(
                        (a, b) => Number(b.id) - Number(a.id),
                    ) || [];
                storage.set(StorageKey, this.nftLists);
            }
        } catch (e) {
            console.error('getRedemptionQueueItems error');
        }
        this.isLoadingList = false;
    }

    @action
    async getLpPrice(lp: string) {
        try {
            const res = await getGraphRequestApi().lpPrice({
                lp,
            });
            this.lpPrice[lp] = new BigNumber(res?.lpPrice.toString()).shiftedBy(
                -18,
            );
        } catch (error) {
            console.error('getPrice error', error);
        }
    }
}

export default new RedemptionQueue();
