import { useEffect, useState } from 'react';
import { useConfig } from './useContract';
import BigNumber from 'bignumber.js';

export interface RedemptionConfig {
    queueLengthSecs: number;
    minQueueLengthSecs: number;
    reduceMaturityStakeSecs: number;
}

export default function useConfigContract() {
    const contract = useConfig();

    async function getVestingRadio() {
        if (!contract) return;

        try {
            const res = await contract.vestingRatio();

            return res;
        } catch (e) {
            console.error('get vestRatio error');
            console.error(e);
        }
    }

    async function getRedemptionConfig(): Promise<
        RedemptionConfig | undefined
    > {
        if (!contract) return;

        try {
            const queueLengthSecs = await contract.queueLengthSecs();
            const minQueueLengthSecs = await contract.minQueueLengthSecs();
            const reduceMaturityStakeSecs =
                await contract.reduceMaturityStakeSecs();

            return {
                queueLengthSecs: queueLengthSecs?.toNumber(),
                minQueueLengthSecs: minQueueLengthSecs?.toNumber(),
                reduceMaturityStakeSecs: reduceMaturityStakeSecs?.toNumber(),
            };
        } catch (e) {
            console.error('getRedemptionConfig error');
            console.error(e);
        }
        return;
    }

    useEffect(() => {
        getVestingRadio();
    }, [contract]);

    return {
        getVestingRadio,
        getRedemptionConfig,
        contract,
    };
}
