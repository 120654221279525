import { getCurrentChainId, getGraphRequestApi } from 'gql';
import { ChainId, DefaultChainId, RPC } from '../constant';
import { action, autorun, makeAutoObservable } from 'mobx';
import Web3 from 'web3';
import { Referrals } from 'gql/graph/__generated__/request';
import BigNumber from 'bignumber.js';
import { ReferralPoints } from 'gql/type';

const InitReferrals: ReferralPoints = {
    referrals: 0,
    stakedByInvitees: 0,
    points: 0,
};

export class User {
    isOpenConnectModal: boolean = false;
    isDark: boolean = true;
    web3: any;
    address: string = '';
    chainId: ChainId = getCurrentChainId();
    connected: boolean = false;
    stakingPoints: number = 0;
    referrals: ReferralPoints = InitReferrals;
    constructor() {
        makeAutoObservable<this>(this);
        autorun(() => {
            if (this.address) {
                this.getUserPoints(this.address);
            } else {
                this.setStakingPoints(0);
                this.setReferrals(InitReferrals);
            }
        });
    }

    toggleConnectModal = () => {
        this.isOpenConnectModal = !this.isOpenConnectModal;
    };

    toggleTheme = () => {
        this.isDark = !this.isDark;
    };

    setWeb3 = (web3: any) => {
        this.web3 = web3;
    };

    setAddress = (address: string) => {
        this.address = address;
    };

    setConnected = (connected: boolean) => {
        this.connected = connected;
    };
    setChainId = (chainId: ChainId) => {
        this.chainId = chainId;
    };

    setStakingPoints = (points: number) => {
        this.stakingPoints = points;
    };

    setReferrals = (res: ReferralPoints) => {
        this.referrals = res;
    };

    @action getUserPoints = async (address: string) => {
        try {
            const res = await getGraphRequestApi().userPoints({
                user: address,
            });
            if (res.stakingPoints) {
                this.setStakingPoints(
                    new BigNumber(res.stakingPoints?.points || 0)
                        .shiftedBy(-18)
                        .toNumber(),
                );
            }

            if (res.referrals) {
                this.setReferrals({
                    points: new BigNumber(res.referrals.points || 0)
                        .shiftedBy(-18)
                        .toNumber(),
                    referrals: Number(res.referrals.referrals),
                    stakedByInvitees: new BigNumber(
                        res.referrals.stakedByInvitees || 0,
                    )
                        .shiftedBy(-18)
                        .toNumber(),
                });
            }
        } catch (e) {
            console.error('getUserPoints error');
        }
    };

    clearLoginInfo = () => {
        this.web3 = new Web3(
            new Web3.providers.HttpProvider(RPC[DefaultChainId]),
        );
        this.chainId = DefaultChainId;
        this.address = '';
        this.connected = false;
        this.stakingPoints = 0;
        this.referrals = InitReferrals;
    };
}

export default new User();
