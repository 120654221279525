import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Block = {
  hash: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  timestamp: Scalars['Int']['output'];
};

export type Deployed = {
  config: Scalars['String']['output'];
  dealer: Scalars['String']['output'];
  eMetis: Scalars['String']['output'];
  eMetisMinter: Scalars['String']['output'];
  enki: Scalars['String']['output'];
  inviterNFT: Scalars['String']['output'];
  l1Bridge: Scalars['String']['output'];
  l1Metis?: Maybe<Scalars['String']['output']>;
  l2Bridge: Scalars['String']['output'];
  l2Metis: Scalars['String']['output'];
  lockingPool: Scalars['String']['output'];
  networkName: Scalars['String']['output'];
  redemptionQueue: Scalars['String']['output'];
  rewardDispatcher: Scalars['String']['output'];
  seMetis: Scalars['String']['output'];
  sequencerAgentTemplate: Scalars['String']['output'];
  startBlock: Scalars['Int']['output'];
  vesting: Scalars['String']['output'];
};

export type Mutation = {
  /** Update LP factors */
  updateLpFactors?: Maybe<Array<Maybe<Transaction>>>;
};

/** Represents a profit of seMetis holder between two timestamps */
export type Profit = {
  /** The APR (with 18 decimals) */
  apr: Scalars['String']['output'];
  /** The assets user holding at the end timestamp (in wei) */
  assets?: Maybe<Scalars['String']['output']>;
  /** The end timestamp */
  end: Scalars['Int']['output'];
  /** The start timestamp */
  start: Scalars['Int']['output'];
  /** The profit value (in wei) */
  value: Scalars['String']['output'];
};

export type Query = {
  /** Get block info */
  block: Block;
  /** Get deployed contract addresses */
  contracts: Deployed;
  /** Get the daily profit of a user between two timestamps */
  dailyProfit?: Maybe<Array<Maybe<Profit>>>;
  /** Get eMetis price of Metis */
  eMetisPrice: Scalars['String']['output'];
  /** Get global profit with APR between two timestamps */
  globalProfit: Profit;
  /** Get global referral points */
  globalReferrals: Referrals;
  /** Get global staking points */
  globalStakingPoints: StakingPoints;
  /** query LP price */
  lpPrice: Scalars['String']['output'];
  /** Get the profit of a user between two timestamps */
  profit?: Maybe<Profit>;
  /** query redemption queue items */
  redemptionQueueItems?: Maybe<Array<Maybe<RedemptionQueueItem>>>;
  /** Get user referral points */
  referrals: Referrals;
  /** Get sequencer info */
  sequencerInfo: SequencerInfo;
  /** Get user staking points */
  stakingPoints: StakingPoints;
  /** Get token info */
  token: Token;
};


export type QueryBlockArgs = {
  number?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDailyProfitArgs = {
  end?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  user: Scalars['String']['input'];
};


export type QueryGlobalProfitArgs = {
  end?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLpPriceArgs = {
  lp: Scalars['String']['input'];
};


export type QueryProfitArgs = {
  end?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  user: Scalars['String']['input'];
};


export type QueryRedemptionQueueItemsArgs = {
  user: Scalars['String']['input'];
};


export type QueryReferralsArgs = {
  user: Scalars['String']['input'];
};


export type QueryStakingPointsArgs = {
  block?: InputMaybe<Scalars['Int']['input']>;
  user: Scalars['String']['input'];
};


export type QueryTokenArgs = {
  key: Scalars['String']['input'];
};

export type RedemptionQueueItem = {
  amount: Scalars['String']['output'];
  holder: Scalars['String']['output'];
  id: Scalars['String']['output'];
  maturity: Scalars['String']['output'];
};

export type Referrals = {
  points: Scalars['String']['output'];
  referrals: Scalars['String']['output'];
  stakedByInvitees: Scalars['String']['output'];
};

export type SequencerInfo = {
  apr: Scalars['String']['output'];
  l1Staked: Scalars['String']['output'];
  syncingToL1: Scalars['String']['output'];
  totalRewards: Scalars['String']['output'];
};

export type StakingPoints = {
  block: Scalars['Int']['output'];
  breakdown?: Maybe<Scalars['String']['output']>;
  points: Scalars['String']['output'];
  time: Scalars['Int']['output'];
};

export type Token = {
  holders: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
  transfers: Scalars['Int']['output'];
};

export type Transaction = {
  hash: Scalars['String']['output'];
};

export type ContractsQueryVariables = Exact<{ [key: string]: never; }>;


export type ContractsQuery = { contracts: { l2Metis: string, eMetis: string, eMetisMinter: string, enki: string, vesting: string, rewardDispatcher: string, seMetis: string, networkName: string, config: string, inviterNFT: string, redemptionQueue: string } };

export type DailyProfitQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>;
  end?: InputMaybe<Scalars['Int']['input']>;
  user: Scalars['String']['input'];
}>;


export type DailyProfitQuery = { dailyProfit?: Array<{ start: number, end: number, value: string, apr: string }> };

export type GlobalPointsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalPointsQuery = { globalStakingPoints: { points: string }, globalReferrals: { points: string, referrals: string, stakedByInvitees: string } };

export type GlobalProfitQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalProfitQuery = { globalProfit: { start: number, end: number, value: string, apr: string } };

export type LpPriceQueryVariables = Exact<{
  lp: Scalars['String']['input'];
}>;


export type LpPriceQuery = { lpPrice: string };

export type ProfitQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>;
  end?: InputMaybe<Scalars['Int']['input']>;
  user: Scalars['String']['input'];
}>;


export type ProfitQuery = { profit?: { start: number, end: number, value: string, apr: string } };

export type RedemptionQueueItemsQueryVariables = Exact<{
  user: Scalars['String']['input'];
}>;


export type RedemptionQueueItemsQuery = { redemptionQueueItems?: Array<{ amount: string, holder: string, id: string, maturity: string }> };

export type SequencerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type SequencerInfoQuery = { sequencerInfo: { apr: string, l1Staked: string, syncingToL1: string, totalRewards: string } };

export type TokenQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type TokenQuery = { token: { holders: number } };

export type UserPointsQueryVariables = Exact<{
  user: Scalars['String']['input'];
}>;


export type UserPointsQuery = { stakingPoints: { points: string }, referrals: { points: string, referrals: string, stakedByInvitees: string } };


export const ContractsDocument = gql`
    query contracts {
  contracts {
    l2Metis
    eMetis
    eMetisMinter
    enki
    vesting
    rewardDispatcher
    seMetis
    networkName
    config
    inviterNFT
    redemptionQueue
  }
}
    `;
export const DailyProfitDocument = gql`
    query dailyProfit($start: Int, $end: Int, $user: String!) {
  dailyProfit(start: $start, end: $end, user: $user) {
    start
    end
    value
    apr
  }
}
    `;
export const GlobalPointsDocument = gql`
    query globalPoints {
  globalStakingPoints {
    points
  }
  globalReferrals {
    points
    referrals
    stakedByInvitees
  }
}
    `;
export const GlobalProfitDocument = gql`
    query globalProfit {
  globalProfit {
    start
    end
    value
    apr
  }
}
    `;
export const LpPriceDocument = gql`
    query lpPrice($lp: String!) {
  lpPrice(lp: $lp)
}
    `;
export const ProfitDocument = gql`
    query profit($start: Int, $end: Int, $user: String!) {
  profit(start: $start, end: $end, user: $user) {
    start
    end
    value
    apr
  }
}
    `;
export const RedemptionQueueItemsDocument = gql`
    query redemptionQueueItems($user: String!) {
  redemptionQueueItems(user: $user) {
    amount
    holder
    id
    maturity
  }
}
    `;
export const SequencerInfoDocument = gql`
    query sequencerInfo {
  sequencerInfo {
    apr
    l1Staked
    syncingToL1
    totalRewards
  }
}
    `;
export const TokenDocument = gql`
    query token($key: String!) {
  token(key: $key) {
    holders
  }
}
    `;
export const UserPointsDocument = gql`
    query userPoints($user: String!) {
  stakingPoints(user: $user) {
    points
  }
  referrals(user: $user) {
    points
    referrals
    stakedByInvitees
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    contracts(variables?: ContractsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ContractsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContractsQuery>(ContractsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'contracts', 'query', variables);
    },
    dailyProfit(variables: DailyProfitQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DailyProfitQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DailyProfitQuery>(DailyProfitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dailyProfit', 'query', variables);
    },
    globalPoints(variables?: GlobalPointsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GlobalPointsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GlobalPointsQuery>(GlobalPointsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'globalPoints', 'query', variables);
    },
    globalProfit(variables?: GlobalProfitQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GlobalProfitQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GlobalProfitQuery>(GlobalProfitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'globalProfit', 'query', variables);
    },
    lpPrice(variables: LpPriceQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LpPriceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LpPriceQuery>(LpPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'lpPrice', 'query', variables);
    },
    profit(variables: ProfitQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ProfitQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProfitQuery>(ProfitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'profit', 'query', variables);
    },
    redemptionQueueItems(variables: RedemptionQueueItemsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RedemptionQueueItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RedemptionQueueItemsQuery>(RedemptionQueueItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'redemptionQueueItems', 'query', variables);
    },
    sequencerInfo(variables?: SequencerInfoQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SequencerInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SequencerInfoQuery>(SequencerInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sequencerInfo', 'query', variables);
    },
    token(variables: TokenQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TokenQuery>(TokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'token', 'query', variables);
    },
    userPoints(variables: UserPointsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserPointsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserPointsQuery>(UserPointsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userPoints', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;