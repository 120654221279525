import { Fade, Modal, Tooltip } from '@mui/material';
import Row, { RowBetween } from 'components/Row';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import Menu from '@mui/material/Menu';
import { CopyLinkLogo } from 'common-helpers/img';
import useCopyClipboard from 'hooks/useCopy';

const StyledModal = styled(Modal)``;

const Content = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    border-radius: 24px;
    overflow: hidden;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% - 20px);
    `};
`;

const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
    height: 300px;
    background: linear-gradient(180deg, #fafafa 0%, #5a382f 0%, #c77353 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: auto;
        height: 178px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 230px;
    `};
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex-direction: column;
    padding: 40px 80px;
    background: linear-gradient(180deg, #fafafa 0%, #5a382f 0%, #c77353 100%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 40px;
    `};
`;

const MediaWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-left: 24px;
    flex-direction: row;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    justify-content: flex-start;
    `};
`;

const MediaImg = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #fff;
`;

const Close = styled.img`
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
`;

const Button = styled.div`
    width: 200px;
    height: 64px;
    background: linear-gradient(270deg, #be692a 0%, #e8c074 100%);
    border-radius: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    border-radius: 12px;
    .MuiList-root {
        padding: 0;
    }
`;

const ShareWrapper = styled.div`
    padding: 18px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
`;

const ShareRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;

    img,
    svg {
        width: 24px;
        height: 24px;
    }

    span {
        color: #0d0102;
        font-size: 14px;
    }
`;
interface Props {
    open: boolean;
    onClose(): void;
    img?: string;
    text?: string;
    type: 'follow' | 'share';
    isOpenClaim?: boolean;
}
export default function ShareModal({
    open,
    onClose,
    img,
    text,
    type,
    isOpenClaim,
}: Props) {
    const [, setCopied] = useCopyClipboard();
    const [showCopy, setShowCopy] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpenMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function copylink() {
        setCopied(`${window.location.origin}/fantasy`);
        setShowCopy(true);
        setTimeout(() => {
            setShowCopy(false);
        }, 1000);
    }
    return (
        <StyledModal open={open} onClose={onClose}>
            <Content>
                <ImageWrapper>
                    <img src={img} alt="" />
                    <Close
                        onClick={onClose}
                        src={require('./imgs/close.png')}
                    />
                </ImageWrapper>
                <TextWrapper>
                    <TYPE.main color="#fff" textAlign={'center'}>
                        {text}
                    </TYPE.main>

                    {type === 'follow' && (
                        <RowBetween>
                            <TYPE.main color="#fff" fontSize={14}>
                                Follow us:
                            </TYPE.main>
                            <MediaWrapper>
                                <a
                                    href="https://twitter.com/ENKIProtocol"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <MediaImg
                                        src={require('assets/images/media/ic_Twitter.png')}
                                    />
                                </a>
                                <a
                                    href="https://t.me/enkixyz"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <MediaImg
                                        src={require('assets/images/media/ic_telegram.png')}
                                    />
                                </a>
                                <a
                                    href="https://medium.com/@ENKIProtocol"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <MediaImg
                                        src={require('assets/images/media/ic_medium.png')}
                                    />
                                </a>
                            </MediaWrapper>
                        </RowBetween>
                    )}
                    {type === 'share' && (
                        <Row justify="center">
                            <Button onClick={handleClick}>Share</Button>

                            <StyledMenu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={isOpenMenu}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <ShareWrapper>
                                    <ShareRow onClick={copylink}>
                                        <CopyLinkLogo />
                                        <Tooltip
                                            // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                                            sx={{ zIndex: 1200 }}
                                            open={showCopy}
                                            title="Copied"
                                            placement="top"
                                            arrow
                                        >
                                            <span>Copy link</span>
                                        </Tooltip>
                                    </ShareRow>
                                    <ShareRow
                                        onClick={() => {
                                            window.open(`
                                        http://twitter.com/share?text=I've just claimed an exclusive NFT on ENKI${
                                            isOpenClaim
                                                ? `.`
                                                : ` and I'm eagerly awaiting the next phase to claim tokens!`
                                        } Check out the details here: &url=${
                                                window.location.origin
                                            }/fantasy&hashtags=ENKI,Fantasy,MetisL2
                                        `);
                                        }}
                                    >
                                        <img
                                            src={require('assets/images/media/ic_Twitter.png')}
                                        />
                                        <span>Share on Twitter</span>
                                    </ShareRow>
                                </ShareWrapper>
                            </StyledMenu>
                        </Row>
                    )}
                </TextWrapper>
            </Content>
        </StyledModal>
    );
}
