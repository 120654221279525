import { OuterLinkSVG } from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { ReactComponent as Speedup } from 'assets/images/speedup.svg';
import Row from 'components/Row';
import Button from 'components/Button';
import { RedemptionQueueItem } from 'gql/graph/__generated__/request';
import { useConstantToken } from 'hooks/useConstantToken';
import BigNumber from 'bignumber.js';
import TooltipInfo from 'components/TooltipInfo';
import moment from 'moment';
import LocklpModal from './LockLpModal';
import { NftInformation } from './type';
import { LockLPBufferTime } from './constant';
import useRedemptionQueueMethods from 'hooks/useRedemptionQueueMethods';
import RedeemModal from './RedeemModal';
import CancelRedeemModal from './CancelRedeemModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';
import { LinearProgress } from '@mui/material';
import { RedemptionConfig } from 'hooks/useConfigContract';
import useSend from 'hooks/useSend';
import redemptionQueue from 'stores/redemptionQueue';
import { SWITCH_NETWORK_CONFIG } from 'constant';
import { getCurrentChainId } from 'gql';
const Wrapper = styled.div`
    width: 100%;
    height: 120px;
    background: linear-gradient(
        225deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 0.3) 100%
    );
    border-radius: 32px;
    backdrop-filter: blur(2.4px);
    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    height: fit-content;
    `};
`;

const IDWrapper = styled.div`
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;

    img {
        width: 56px;
        height: 56px;
        background: #372312;
        border-radius: 6px;
    }

    svg {
        color: #dca45c;
        width: 12px;
        height: 12px;
        margin-left: 10px;
        cursor: pointer;
    }
`;

const StyledLinearProgress = styled(LinearProgress)`
    width: 200px;
    height: 8px;
    background: #372312;
    border-radius: 2px;
    position: relative;
    .MuiLinearProgress-bar {
        background: #dca45c;
    }
`;

const StyleProgressWrapper = styled(AutoColumn)`
    width: 200px;
`;

const StyledSpeedup = styled(Speedup)`
    width: 20px;
    height: 20px;
    margin-left: 8px;
`;

const StyledButtonGroup = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 20px;
    flex-grow: 1;

    .textButtonWrapper {
        justify-items: flex-end;
    }

    &.speedUp {
        padding-top: 28px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        padding-top: unset;
        `};
    }
    button {
        flex-shrink: 0;
        padding: 6px 16px !important;
        height: fit-content !important;
        gap: 4px;
        &.speedUpButton {
            background: #a77c5c !important;
            opacity: 1;
            padding-top: 3px !important;
            padding-bottom: 3px !important;
            padding-left: 6px !important;
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
`;

interface Props {
    redemptionQueueItem: RedemptionQueueItem;
    chainTime?: number;
    redemptionConfig?: RedemptionConfig;
}

export default function RedeemNFT({
    redemptionQueueItem,
    chainTime,
    redemptionConfig,
}: Props) {
    const { redemptionQueueContract, getNft } = useRedemptionQueueMethods();
    const [isOpenLockLpModal, setIsOpenLockLpModal] = useState(false);
    const { eMetis } = useConstantToken();
    const graphMaturityTime = useMemo(() => {
        return moment(Number(redemptionQueueItem.maturity) * 1000);
    }, [redemptionQueueItem.maturity]);

    // const [maturityTime, setMaturityTime] = useState(undefined);
    const [nftInformation, setNftInformation] = useState<NftInformation>();
    const [isOpenRedeemModal, setIsOpenRedeemModal] = useState(false);
    const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
    const send = useSend();

    async function unlockLP() {
        if (!redemptionQueueContract) return;
        await send({
            contract: redemptionQueueContract,
            method: 'unlockLpToken',
            params: [redemptionQueueItem.id],
            pendingText: `Unlocking the Locked ENKI LP of redemption #${redemptionQueueItem.id}`,
            summary: `Unlocked the Locked ENKI LP of redemption #${redemptionQueueItem.id}`,
            onSuccess: async () => {
                await redemptionQueue.getNftList();
                await getNftInformation();
            },
        });
    }
    async function getNftInformation() {
        if (!redemptionQueueContract) return;
        const res = await getNft(redemptionQueueItem.id);
        setNftInformation(res);
    }

    const isSpeedUp = useMemo(() => {
        if (!nftInformation) return false;
        return new BigNumber(
            nftInformation?.lpLockAmount?.toString(),
        ).isGreaterThan(0);
    }, [nftInformation]);

    const LeftTimeStr = useMemo(() => {
        if (!chainTime) return '-';

        const durationTime =
            (Number(redemptionQueueItem.maturity) - chainTime) * 1000;
        const duration = moment.duration(durationTime > 0 ? durationTime : 0);
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        return `${days} days, ${hours} hrs, ${minutes} mins`;
    }, [redemptionQueueItem.maturity, chainTime]);

    const isSpeedUpOutOfDate = useMemo(() => {
        if (!chainTime || !redemptionConfig) return false;

        const maturity = Number(redemptionQueueItem.maturity);
        return (
            maturity - chainTime - LockLPBufferTime <
            redemptionConfig?.minQueueLengthSecs
        );
    }, [redemptionQueueItem, chainTime, redemptionConfig]);

    useEffect(() => {
        getNftInformation();
    }, [redemptionQueueContract]);

    const canRedeem = useMemo(() => {
        return !!(
            chainTime && chainTime >= Number(redemptionQueueItem.maturity)
        );
    }, [chainTime, redemptionQueueItem.maturity]);

    const progress = useMemo(() => {
        if (
            redemptionQueueItem.maturity &&
            chainTime &&
            redemptionConfig?.queueLengthSecs
        ) {
            const p =
                (1 -
                    (Number(redemptionQueueItem.maturity) - chainTime) /
                        redemptionConfig?.queueLengthSecs) *
                100;

            return p > 100 ? 100 : p < 0 ? 0 : p;
        }
        return 0;
    }, [
        redemptionQueueItem.maturity,
        chainTime,
        redemptionConfig?.queueLengthSecs,
    ]);

    const isCanUnLockLP = useMemo(() => {
        if (
            !nftInformation?.lpLockMaturity ||
            !Number(nftInformation.lpLockMaturity) ||
            !chainTime
        )
            return false;
        return chainTime > Number(nftInformation.lpLockMaturity);
    }, [nftInformation?.lpLockMaturity, chainTime]);

    const leftLockUpLPStr = useMemo(() => {
        if (
            !chainTime ||
            !nftInformation?.lpLockMaturity ||
            !Number(nftInformation?.lpLockMaturity)
        )
            return '-';

        const duration = moment.duration(
            (Number(nftInformation.lpLockMaturity) - chainTime) * 1000,
        );
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        return `${days} days, ${hours} hrs, ${minutes} mins`;
    }, [nftInformation?.lpLockMaturity, chainTime]);

    if (nftInformation?.lpClaimed && nftInformation?.hasBeenRedeemed)
        return null;

    return (
        <Wrapper>
            <IDWrapper>
                <img src={require('assets/images/redeem/ticket.png')} alt="" />
                <TYPE.main
                    color={'#DCA45C'}
                    fontWeight={'bold'}
                    fontSize={18}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    ID#{redemptionQueueItem.id}
                    <OuterLinkSVG
                        onClick={() => {
                            window.open(
                                `${
                                    SWITCH_NETWORK_CONFIG[getCurrentChainId()]
                                        .blockExplorerUrls
                                }/token/${
                                    redemptionQueueContract?.address
                                }/instance/${redemptionQueueItem.id}`,
                            );
                        }}
                    />
                </TYPE.main>
            </IDWrapper>
            <StyleProgressWrapper gap="8px">
                <TooltipInfo
                    text={`The redemption end time: ${graphMaturityTime.format(
                        'MMM DD, YYYY, HH:MM LT  Z',
                    )}`}
                >
                    <TYPE.main color="#DCA45C">{LeftTimeStr}</TYPE.main>
                </TooltipInfo>
                <Row>
                    <StyledLinearProgress
                        variant="determinate"
                        value={progress}
                    />

                    {isSpeedUp && <StyledSpeedup />}
                </Row>
            </StyleProgressWrapper>
            <TYPE.main color="#DCA45C" fontSize={18} fontWeight={600}>
                {new BigNumber(redemptionQueueItem.amount)
                    .shiftedBy(-eMetis.decimals)
                    .toFixed(4)}{' '}
                eMetis
            </TYPE.main>
            <StyledButtonGroup
                className={isSpeedUp && !isCanUnLockLP ? 'speedUp' : ''}
            >
                {isCanUnLockLP ? (
                    <Button
                        size="small"
                        disabled={nftInformation?.lpClaimed}
                        onClick={unlockLP}
                    >
                        {nftInformation?.lpClaimed
                            ? 'LP unlocked'
                            : 'Unlock LP'}
                    </Button>
                ) : canRedeem && !isSpeedUp ? null : (
                    <AutoColumn
                        style={{ flexShrink: 0 }}
                        gap="8px"
                        className="textButtonWrapper"
                    >
                        <TooltipInfo
                            text={
                                isSpeedUp
                                    ? `Please Wait until ${moment(
                                          Number(
                                              nftInformation?.lpLockMaturity ||
                                                  0,
                                          ) * 1000,
                                      ).format(
                                          'MMM DD, YYYY, HH:MM LT  Z',
                                      )} to unlock ENKI LP`
                                    : 'No need to speed up the redemption.'
                            }
                            disabled={!isSpeedUp && !isSpeedUpOutOfDate}
                        >
                            {isSpeedUp ? (
                                <Button size="small" disabled>
                                    Waiting for unlock
                                </Button>
                            ) : (
                                <Button
                                    size="small"
                                    disabled={isSpeedUpOutOfDate}
                                    onClick={() => {
                                        setIsOpenLockLpModal(true);
                                    }}
                                >
                                    Speed Up
                                </Button>
                            )}
                        </TooltipInfo>

                        {isSpeedUp && (
                            <TYPE.main
                                fontSize={14}
                                mobileFz={12}
                                color="#A77C5C ;
"
                            >
                                {leftLockUpLPStr}
                            </TYPE.main>
                        )}
                    </AutoColumn>
                )}
                {!nftInformation?.hasBeenRedeemed && (
                    <TooltipInfo
                        text="Please wait until the redemption time comes before redeeming"
                        disabled={canRedeem}
                    >
                        <Button
                            size="small"
                            type="outline"
                            disabled={!canRedeem}
                            onClick={() => {
                                setIsOpenRedeemModal(true);
                            }}
                        >
                            Redeem
                        </Button>
                    </TooltipInfo>
                )}

                {!nftInformation?.hasBeenRedeemed && !canRedeem && (
                    <Button
                        size="small"
                        type="outline"
                        onClick={() => {
                            setIsOpenCancelModal(true);
                        }}
                    >
                        Cancel
                    </Button>
                )}
            </StyledButtonGroup>
            {isOpenLockLpModal && (
                <LocklpModal
                    isOpen={isOpenLockLpModal}
                    onClose={() => setIsOpenLockLpModal(false)}
                    redemptionQueueItem={redemptionQueueItem}
                    isSpeedUpOutDate={isSpeedUpOutOfDate}
                    chainTime={chainTime}
                    redemptionConfig={redemptionConfig}
                    updateNFTInformation={getNftInformation}
                />
            )}
            <RedeemModal
                isOpen={isOpenRedeemModal}
                onClose={() => {
                    setIsOpenRedeemModal(false);
                }}
                redemptionQueueItem={redemptionQueueItem}
                updateNFTInformation={getNftInformation}
            />
            <CancelRedeemModal
                isOpen={isOpenCancelModal}
                onClose={() => {
                    setIsOpenCancelModal(false);
                }}
                redemptionQueueItem={redemptionQueueItem}
                updateNFTInformation={getNftInformation}
            />
        </Wrapper>
    );
}
