import { ApolloClient } from '@apollo/client';
import React from 'react';
import { Client } from 'gql/type';
import { getApolloClient } from 'gql';

const apolloMultiClientContext = React.createContext<{
    getClient(clientName: string | undefined): ApolloClient<any> | undefined;
}>({
    getClient() {
        return undefined;
    },
});

export const useApolloMultiClient = () => {
    return React.useContext(apolloMultiClientContext);
};

export const ApolloMultiClientProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const getClient = (clientName: string) => {
        return getApolloClient(clientName);
    };

    return (
        <apolloMultiClientContext.Provider value={{ getClient }}>
            {children}
        </apolloMultiClientContext.Provider>
    );
};
