/* eslint-disable react/jsx-key */
import {
    ContentTitle,
    DataColumn,
    DataDesc,
    DataLink,
    DataTitle,
} from 'components/Page/DataComponents';
import DataWrapper from 'components/Page/DataWrapper';

import PageWrapper from 'components/Page/PageWrapper';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import Row, { RowBetween } from 'components/Row';
import Button from 'components/Button';
import { AutoColumn } from 'components/Column';
import BigNumber from 'bignumber.js';
import TokenInput from 'components/TokenInput/SwitchTokenInput';
import { useConstantToken } from 'hooks/useConstantToken';
import user from 'stores/user';
import useTokenBalance from 'hooks/useTokenBalance';
import { ReactComponent as PlusSVG } from 'assets/images/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/images/minus.svg';

import PageContent, {
    ButtonGroup,
    LeftContent,
    RightContent,
    RightContentTitle,
    TransactionDetail,
    TransactionText,
    TransactionTokenLogo,
} from 'components/Page/PageContent';
import { observer } from 'mobx-react';
import { formatNumber } from 'utils';
import { isAddress } from 'viem';
import RedeemNFT from './RedeemNFT';
import TooltipInfo from 'components/TooltipInfo';

import useTokenApprove from 'hooks/useTokenApprove';

import { useRedemptionQueue } from 'hooks/useContract';
import useSend from 'hooks/useSend';
import { getGraphRequestApi } from 'gql';
import { RedemptionQueueItem } from 'gql/graph/__generated__/request';
import { useBlock } from 'wagmi';
import redemptionQueue from 'stores/redemptionQueue';
import useConfigContract, { RedemptionConfig } from 'hooks/useConfigContract';
import useExploreLink from 'hooks/useExploreLink';
import contracts from 'stores/contracts';
import { isMobile } from 'react-device-detect';
import Loading from 'components/Loading';

const StyledCheckboxRow = styled(Row)`
    svg {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    gap: 12px;
`;

const StyledIputRow = styled(RowBetween)`
    height: 64px;
    background: #000000;
    border-radius: 16px;
    padding: 16px;

    input {
        background: transparent;
        color: #fff;
        border: none;
        flex-grow: 1;
    }

    button {
        color: #dca45c;
        font-size: 16px;
        flex-shrink: 0;
        cursor: pointer;
        border: none !important;
        height: 100% !important;
    }
`;

const NFTContent = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;

    .placeHolderBlock {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 20px;
        justify-content: center;
        flex-direction: column;
        img {
            width: 160px;
            height: 160px;
        }

        svg {
            color: ${({ theme }) => theme.c.mainColor};
        }
    }
`;
function Redeem() {
    const block = useBlock();
    const chainTime = useMemo(() => {
        return Number(block.data?.timestamp || 0);
    }, [block]);

    const [inputValue, setInputValue] = useState(new BigNumber(0));
    const { getRedemptionConfig, contract: configContract } =
        useConfigContract();
    const [redemptionConfig, setRedemptionConfig] =
        useState<RedemptionConfig>();
    const { metis, eMetis } = useConstantToken();

    const { balance: metisBalance, getBalance: getMetisBalance } =
        useTokenBalance(metis.address, user.address);
    const { balance: eMetisBalance, getBalance: getEMetisBalance } =
        useTokenBalance(eMetis.address, user.address);

    const [inputValid, setInputValid] = useState(false);
    const [isSelectedRecip, setIsSelectedRecip] = useState(false);
    const [inputRecipient, setInputRecipient] = useState('');
    const redemptionQueueContract = useRedemptionQueue();

    const isRecipientValid = useMemo(() => {
        return !inputRecipient || isAddress(inputRecipient);
    }, [inputRecipient]);
    const {
        approved: approvedRedemptionQueue,
        approve: approveRedemptionQueue,
    } = useTokenApprove(
        eMetis,
        redemptionQueueContract?.address || '',
        inputValue,
    );
    const send = useSend();
    const { getAddressUrl } = useExploreLink();

    async function enterRedemptionQueue() {
        if (!redemptionQueueContract) return;

        await send({
            contract: redemptionQueueContract,
            method: 'enterRedemptionQueue',
            params: [
                inputRecipient || user.address,
                inputValue.shiftedBy(eMetis.decimals).toFixed(0),
            ],
            pendingText: `${inputValue.toFixed()} eMetis will be added to the queue`,
            summary: `${inputValue.toFixed()} eMetis has added to the queue`,
            onSuccess: () => {
                getEMetisBalance();
                setInputValue(new BigNumber(0));
                redemptionQueue.getNftList();
            },
        });
    }

    async function getRedemptionConfigFunc() {
        const res = await getRedemptionConfig();
        setRedemptionConfig(res);
    }

    useEffect(() => {
        getRedemptionConfigFunc();
    }, [configContract]);

    useEffect(() => {
        redemptionQueue.getNftList();
        const interval = setInterval(() => {
            redemptionQueue.getNftList();
        }, 60000);
        return () => {
            clearInterval(interval);
        };
    }, [user.address]);

    useEffect(() => {
        setInputRecipient(user.address);
    }, [user.address]);

    return (
        <PageWrapper>
            {/* <ComingSoonWrapper>
                <ComingSoonImg
                    src={require('assets/images/ic_comingsoon.png')}
                />
            </ComingSoonWrapper> */}

            <DataWrapper>
                <DataColumn>
                    <DataTitle>8-20 Days</DataTitle>
                    <DataDesc>
                        Waiting Period
                        <TooltipInfo
                            text={
                                'Period of time to wait before redeeming without fees'
                            }
                        />
                    </DataDesc>
                </DataColumn>
                <DataColumn>
                    <DataTitle>0.5 %</DataTitle>
                    <DataDesc>
                        Cancellation Fees{' '}
                        <TooltipInfo
                            text={
                                'Penalty applied when click Cancel button before the end of waiting period. By cancellation, you will get your eMetis back minus this fee, not Metis'
                            }
                        />
                    </DataDesc>
                </DataColumn>
                <DataColumn>
                    <DataTitle>0 %</DataTitle>
                    <DataDesc>Redemption Fees</DataDesc>
                </DataColumn>
                <DataColumn leftBorder={!isMobile}>
                    <DataTitle>CONTRACTS</DataTitle>
                    <DataLink
                        href={getAddressUrl(
                            contracts.contracts?.redemptionQueue || '',
                        )}
                    >
                        <DataDesc>Redemption Queue</DataDesc>
                    </DataLink>
                </DataColumn>
            </DataWrapper>
            <ContentTitle>Enter Redemption Queue</ContentTitle>
            <PageContent>
                <LeftContent>
                    <TokenInput
                        value={inputValue}
                        onChange={setInputValue}
                        inputPercent={[50]}
                        token={eMetis}
                        onInputValid={setInputValid}
                    />
                    <AutoColumn gap="16px" style={{ width: '100%' }}>
                        <RowBetween>
                            <TYPE.main fontSize={14}>FEE | 0.00%</TYPE.main>
                            <TYPE.main fontSize={14}>(0.00 Metis)</TYPE.main>
                        </RowBetween>
                        {/* <RowBetween>
                            <TYPE.main fontSize={14}>REDEEMABLE DATE</TYPE.main>
                            <TYPE.main fontSize={14}>{moment().add('days',20).format()}</TYPE.main>
                        </RowBetween> */}
                    </AutoColumn>
                    <TransactionDetail>
                        <RowBetween>
                            <TransactionText>YOU WILL RECEIVE</TransactionText>

                            <TransactionText>
                                <TransactionTokenLogo src={metis.img} />
                                {inputValue.toFixed()} Metis
                            </TransactionText>
                        </RowBetween>
                    </TransactionDetail>
                    {/* <StyledCheckboxRow>
                        {isSelectedRecip ? (
                            <MinusSVG
                                onClick={() => {
                                    setIsSelectedRecip(false);
                                }}
                            />
                        ) : (
                            <PlusSVG
                                onClick={() => {
                                    setIsSelectedRecip(true);
                                }}
                            />
                        )}
                        <TYPE.main>Recipient address </TYPE.main>
                    </StyledCheckboxRow> */}
                    {isSelectedRecip && (
                        <>
                            <StyledIputRow>
                                <input
                                    placeholder="Enter recipient address"
                                    value={inputRecipient}
                                    onChange={(e: any) => {
                                        setInputRecipient(e.target.value);
                                    }}
                                />
                                {user.connected && (
                                    <Button
                                        type={
                                            user.connected
                                                ? 'outline'
                                                : 'primary'
                                        }
                                        disabled={
                                            inputRecipient.toLowerCase() ===
                                            user.address.toLowerCase()
                                        }
                                        onClick={() => {
                                            setInputRecipient(user.address);
                                        }}
                                    >
                                        User current address
                                    </Button>
                                )}
                            </StyledIputRow>
                            {!isRecipientValid && (
                                <Row>
                                    <TYPE.main color="red">
                                        Invalid address
                                    </TYPE.main>
                                </Row>
                            )}
                            <TYPE.main fontSize={14}>
                                Tickets won’t show here when using a different
                                address; they’ll appear in the recipient
                                address’s redeem tickets.
                            </TYPE.main>
                        </>
                    )}

                    <ButtonGroup>
                        {user.connected && !approvedRedemptionQueue && (
                            <Button
                                disabled={
                                    (!inputValid && !isRecipientValid) ||
                                    inputValue.isEqualTo(0)
                                }
                                onClick={approveRedemptionQueue}
                            >
                                Approve
                            </Button>
                        )}
                        <Button
                            auth
                            disabled={
                                !approvedRedemptionQueue ||
                                (!inputValid && !isRecipientValid) ||
                                inputValue.isEqualTo(0)
                            }
                            onClick={enterRedemptionQueue}
                        >
                            Enter Queue
                        </Button>
                    </ButtonGroup>
                </LeftContent>
                <RightContent>
                    <AutoColumn gap="48px">
                        <RightContentTitle>Current Stake</RightContentTitle>
                        <AutoColumn gap="32px">
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(metisBalance)} Metis
                                </DataTitle>
                                <DataDesc>Your Metis Balance</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(eMetisBalance)} eMetis
                                </DataTitle>
                                <DataDesc>Your eMetis Balance</DataDesc>
                            </DataColumn>
                            <DataLink href="https://docs.enkixyz.com/guides/redeem">
                                <DataDesc>Documentation</DataDesc>
                            </DataLink>
                        </AutoColumn>
                    </AutoColumn>
                </RightContent>
            </PageContent>
            <NFTContent>
                <ContentTitle>Redeem Tickets</ContentTitle>
                {redemptionQueue.isLoadingList &&
                    redemptionQueue.redemptionQueueItems.length === 0 && (
                        <div className="placeHolderBlock">
                            <Loading size={60} />

                            <TYPE.main fontSize={16} color="mainColor">
                                Loading...
                            </TYPE.main>
                        </div>
                    )}

                {!redemptionQueue.isLoadingList &&
                    redemptionQueue.redemptionQueueItems.length === 0 && (
                        <div className="placeHolderBlock">
                            <img
                                src={require('assets/images/redeem/empty.png')}
                                alt=""
                            />
                            <TYPE.main fontSize={16} color="mainColor">
                                You have no tickets to redeem.
                            </TYPE.main>
                        </div>
                    )}
                {redemptionQueue.redemptionQueueItems.map((item) => (
                    <RedeemNFT
                        key={item.id}
                        redemptionQueueItem={item}
                        chainTime={chainTime}
                        redemptionConfig={redemptionConfig}
                    />
                ))}
            </NFTContent>
            {/* <RedeemModal /> */}
            {/* <CancelRedeemModal /> */}
            {/* <LocklpModal /> */}
        </PageWrapper>
    );
}
export default observer(Redeem);
