import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Homepage from 'pages/homepage';
// import Forbiden403 from 'pages/403';
import styled from 'styled-components';
import UseWalletConnectHook from 'hooks/useWalletconnect';
import { observer } from 'mobx-react';
import user from 'stores/user';
import ComingSoon from 'pages/comingsoon';
import TransactionConfirmationModal from 'components/TransactionConfirmationModal';
import useTokenPrice from 'hooks/useTokenPrice';
import Footer from 'components/Footer';
import useGetContractByGraph from 'hooks/useGetContractByGraph';
import application from 'stores/application';
import classNames from 'classnames';
import ComingSoonImgMobile from 'assets/images/comingsoon-mobile.png';
import ComingSoonImg from 'assets/images/comingsoon.png';
import { isMobile } from 'react-device-detect';
import Fantasy from 'pages/fantasy';
import Mint from 'pages/mint';
import FantasyTips from 'components/FantasyTips';
import Stake from 'pages/stake';
import Vesting from 'pages/vesting';
import Redeem from 'pages/redeem';
import PreStakingModal from 'components/Page/PreStakingModal';
import Portfolio from 'pages/portfolio';
import GlobalSimepleDialog from 'components/Dialog/GlobalSimpleDialog';
import Defi from 'pages/defi';

const Container = styled.div`
    width: 100vw;
    // box-sizing: border-box;
    // padding-top: 93px;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /* background: url('./assets/images/bg.png') no-repeat; */
    background: #150701;
    background-size: 100% 100%;
    &.comingSoonBg {
        background-color: #000;
    }
`;

const MainView = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    color: white;
    padding-top: 120px;
    padding-bottom: 100px;
    background-image: url(${require('assets/images/page-bg.png')});
    background-size: cover;
    background-position: top center;
    z-index: 0;
    ${({ theme }) => theme.mediaWidth.upToSmall`
     padding-top: 100px;
     padding-bottom: 60px;
    `}
    &.comingSoonBg {
        background: url(${isMobile ? ComingSoonImgMobile : ComingSoonImg})
            center;
        background-size: cover;
        ${({ theme }) => theme.mediaWidth.upToMoreLarge`
        background-size: cover;
        `};
        ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 0;
        `}
    }

    &.homeView {
        background-image: none;
        padding-bottom: 0;
    }
`;

const StyledFantasyTips = styled(FantasyTips)`
    margin: 0 auto;

    /* ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 100px;
    `}; */
`;

function App() {
    const { i18n } = useTranslation();
    // const { isLoading } = useConnect();
    const { isOpenConnectModal } = user;

    const location = useLocation();
    // const history = useHistory();

    useEffect(() => {
        if (i18n.language === 'ru') {
            document.getElementsByTagName('html')[0].className = 'ru';
        }
    }, [i18n]);

    const isIframeLink = location && location.pathname === '/banxa';
    const is403 = location && location.pathname === '/403';
    const isHome = location && location.pathname === '/home';
    useTokenPrice();
    useGetContractByGraph();

    // useEffect(() => {
    //     if ((window as any).ethereum) {
    //         ((window as any).ethereum as any).on(
    //             'chainChanged',
    //             async (chanIdHex: string) => {
    //                 (window as any).location.reload();
    //             },
    //         );
    //     }
    // }, []);

    const isComingSoonPath = location.pathname === '/';
    const showComingSoon = application.comingSoon && isComingSoonPath;
    const isFantasy = useMemo(() => {
        return location.pathname === '/fantasy';
    }, [location.pathname]);
    const isHomepage = useMemo(
        () => location.pathname === '/home',
        [location.pathname],
    );

    useEffect(() => {
        const container = document.getElementById('globalContainer');
        container?.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            <UseWalletConnectHook />
            <TransactionConfirmationModal />
            <Container
                id="globalContainer"
                className={classNames(
                    'container',
                    showComingSoon ? 'comingSoonBg' : '',
                )}
            >
                {!is403 && !isHome && <Header />}
                <MainView
                    className={classNames(
                        'mainView',
                        showComingSoon ? 'comingSoonBg' : '',
                        isHomepage ? 'homeView' : '',
                    )}
                >
                    {!isFantasy && !isHomepage && <StyledFantasyTips />}
                    <Switch>
                        {application.comingSoon ? (
                            <>
                                <Route exact path="/">
                                    <ComingSoon />
                                </Route>
                            </>
                        ) : (
                            <>
                                <Route exact path="/fantasy">
                                    <Fantasy />
                                </Route>
                                <Route path="/home">
                                    <Homepage />
                                </Route>
                                <Route exact path="/welcome">
                                    <Redirect to="/home" />
                                </Route>
                                <Route path="/mint" exact>
                                    <Mint />
                                </Route>

                                <Route path="/stake">
                                    <Stake />
                                </Route>
                                <Route path="/vesting">
                                    <Vesting />
                                </Route>
                                <Route path="/redeem">
                                    <Redeem />
                                </Route>
                                <Route path="/defi">
                                    <Defi />
                                </Route>
                                <Route path="/portfolio">
                                    <Portfolio />
                                </Route>

                                <Route exact path="/">
                                    <Redirect to="/home" />
                                </Route>
                            </>
                        )}
                    </Switch>
                </MainView>
                {!application.comingSoon && <Footer />}
            </Container>
            <GlobalSimepleDialog />
            <PreStakingModal />
        </>
    );
}

export default observer(App);
