import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import { useContract } from './useContract';
import DistributorABI from 'contracts/distributor/abi.json';
import NFTDistributorABI from 'contracts/nftDistributor/abi.json';
import BigNumber from 'bignumber.js';
import useWatchAccountChange from './useWatchAccountChange';
import { getDistributorApi } from 'gql';
import { Distributor, MerkleLeaf } from 'gql/distributor/__generated__/request';

export default function useDistributor(distributor?: Distributor) {
    const [userMerkleLeaf, setUserMerkleLeaf] = useState<
        MerkleLeaf | undefined
    >();
    // -1 = init , 0 = no airdrop, 1 = has airdrop
    const [isUserHasAirdrop, setIsUserHasAirdrop] = useState(-1);
    const [isClaimedNFT, setIsClaimedNft] = useState(false);
    const [nftToknURI, setNftTokenURI] = useState('');
    const [claimableENKIAmount, setClaimableENKIAmount] = useState(
        new BigNumber(-1),
    );
    const [withdrawEnabledBefore, setWithdrawEnabledBefore] = useState(0);
    const [claimCount, setClaimCount] = useState<number>(0);
    const distributorContract = useContract(
        distributor?.distributorAddress,
        DistributorABI,
    );

    const nftTokenId = userMerkleLeaf?.index;
    const nftDistributorContract = useContract(
        distributor?.nftAddress,
        NFTDistributorABI,
    );

    useWatchAccountChange({
        onChange() {
            // reinit data when switch account
            setUserMerkleLeaf(undefined);
            setIsUserHasAirdrop(-1);
            setIsClaimedNft(false);
        },
    });

    async function getUserMerkleLeaf() {
        if (user.address && distributor) {
            try {
                const res = await getDistributorApi().merkleLeaf({
                    address: user.address,
                    tree: distributor.nftSymbol,
                });
                if (res && res.merkleLeaf?.index >= 0) {
                    setUserMerkleLeaf(res.merkleLeaf);
                    setIsUserHasAirdrop(1);
                } else {
                    setIsUserHasAirdrop(0);
                }
            } catch (e) {
                console.error(
                    'getUserMerkleLeaf error',
                    user.address,
                    distributor,
                );
                console.error(e);
            }
        }
    }

    async function getIsClaimedNFT() {
        try {
            if (distributorContract && userMerkleLeaf) {
                const res = await distributorContract.isClaimed(
                    userMerkleLeaf.index,
                );
                setIsClaimedNft(res);
            }
        } catch (e) {
            console.error('getIsClaimedNFT error', userMerkleLeaf);
            console.error(e);
        }
    }

    async function getWithdrawEnabledBefore(): Promise<number> {
        try {
            if (distributorContract) {
                const res = await distributorContract.withdrawEnabledBefore();
                setWithdrawEnabledBefore(res?.toNumber());
                return res?.toNumber();
            }
        } catch (e) {
            console.error(
                'getWithdrawEnabledBefore error',
                distributorContract,
            );
            console.error(e);
        }
        return 0;
    }

    async function getENKIClaimableAmounts() {
        if (nftDistributorContract && nftTokenId !== undefined) {
            try {
                const res = await nftDistributorContract.claimableAmounts(
                    nftTokenId,
                );
                setClaimableENKIAmount(
                    new BigNumber(res?.toString()).shiftedBy(-18),
                );
            } catch (e) {
                console.error(
                    'getENKIClaimableAmounts error',
                    nftDistributorContract,
                    nftTokenId,
                );
            }
        }
    }

    async function getClaimCount() {
        if (distributorContract) {
            try {
                const res = await distributorContract.claimCount();
                setClaimCount(res?.toNumber());
            } catch (e) {
                console.error('getClaimCount error', distributorContract);
            }
        }
    }

    async function getNFTLogoURI() {
        try {
            if (
                nftDistributorContract &&
                nftTokenId !== undefined &&
                isClaimedNFT
            ) {
                const res = await nftDistributorContract.tokenURI(nftTokenId);
                if (res) {
                    setNftTokenURI(res);
                } else {
                    console.error('getNFTLogoURI', nftDistributorContract);
                }
            }
        } catch (e) {
            console.error(
                'getNFTLogoURI error',
                nftDistributorContract,
                nftTokenId,
            );
            console.error(e);
        }
    }

    useEffect(() => {
        getIsClaimedNFT();
    }, [distributorContract, userMerkleLeaf, user.address]);

    useEffect(() => {
        getClaimCount();
    }, [distributorContract]);

    useEffect(() => {
        getNFTLogoURI();
        getENKIClaimableAmounts();
    }, [nftDistributorContract, nftTokenId, isClaimedNFT]);

    const isClaimedENKI = useMemo(() => {
        if (!isClaimedNFT) return false;
        if (claimableENKIAmount.eq(0)) return true;
        return false;
    }, [isClaimedNFT, claimableENKIAmount]);

    useEffect(() => {
        // unwatch();
    }, []);

    return {
        distributorContract,
        getUserMerkleLeaf,
        isUserHasAirdrop,
        isClaimedNFT,
        isClaimedENKI,
        nftToknURI,
        userMerkleLeaf,
        nftTokenId,
        getWithdrawEnabledBefore,
        claimCount,
        getClaimCount,
        getENKIClaimableAmounts,
        setClaimableENKIAmount,
        setIsClaimedNft,
        claimableENKIAmount,
    };
}
