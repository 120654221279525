import BigNumber from 'bignumber.js';
import { useContract } from './useContract';

import ERC20_ABI from 'contracts/erc20.json';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

export default function useTokenBalance(
    token: string,
    account?: string,
    intervalTime = 10000,
) {
    const [balance, setBalance] = useState(new BigNumber(0));
    const tokenContract = useContract(token, ERC20_ABI);
    const [loadingOnce, setLoadingOnce] = useState(false);
    const { address } = useAccount();
    const fetchAddress = account || address;

    async function getBalance() {
        if (!fetchAddress || !tokenContract?.address) return;
        try {
            const balance = await tokenContract?.balanceOf(fetchAddress);

            const decimals = await tokenContract?.decimals();

            if (balance && decimals) {
                setBalance(
                    new BigNumber(balance.toString()).shiftedBy(
                        -Number(decimals),
                    ),
                );
            }
        } catch (e) {
            console.error(
                `useTokenBalance error, token: ${token}, account: ${fetchAddress} `,
            );
            console.error(e);
        }
        setLoadingOnce(true);
    }

    useEffect(() => {
        getBalance();
        const interval = setInterval(() => {
            getBalance();
        }, intervalTime);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [fetchAddress, intervalTime, tokenContract]);
    useEffect(() => {
        setBalance(new BigNumber(0));
        setLoadingOnce(false);
    }, [fetchAddress]);

    return {
        balance,
        getBalance,
        loadingOnce,
    };
}
