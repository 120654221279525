import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Distributor = {
  distributorAddress: Scalars['String']['output'];
  merkleRoot: Scalars['String']['output'];
  nftAddress: Scalars['String']['output'];
  nftName: Scalars['String']['output'];
  nftSymbol: Scalars['String']['output'];
  tokenAddress: Scalars['String']['output'];
};

export type MerkleLeaf = {
  amount: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  proof: Array<Scalars['String']['output']>;
};

export type Query = {
  /** get merkle trees */
  distributors?: Maybe<Array<Distributor>>;
  /** get merkle leaf */
  merkleLeaf: MerkleLeaf;
};


export type QueryMerkleLeafArgs = {
  address: Scalars['String']['input'];
  tree?: InputMaybe<Scalars['String']['input']>;
};

export type DistributorsQueryVariables = Exact<{ [key: string]: never; }>;


export type DistributorsQuery = { distributors?: Array<{ merkleRoot: string, nftAddress: string, nftName: string, nftSymbol: string, tokenAddress: string, distributorAddress: string }> };

export type MerkleLeafQueryVariables = Exact<{
  address: Scalars['String']['input'];
  tree: Scalars['String']['input'];
}>;


export type MerkleLeafQuery = { merkleLeaf: { amount: string, index: number, proof: Array<string> } };


export const DistributorsDocument = gql`
    query distributors {
  distributors {
    merkleRoot
    nftAddress
    nftName
    nftSymbol
    tokenAddress
    distributorAddress
  }
}
    `;
export const MerkleLeafDocument = gql`
    query merkleLeaf($address: String!, $tree: String!) {
  merkleLeaf(address: $address, tree: $tree) {
    amount
    index
    proof
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    distributors(variables?: DistributorsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DistributorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DistributorsQuery>(DistributorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'distributors', 'query', variables);
    },
    merkleLeaf(variables: MerkleLeafQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MerkleLeafQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MerkleLeafQuery>(MerkleLeafDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'merkleLeaf', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;