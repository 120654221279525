import user from 'stores/user';
import { useRedemptionQueue } from './useContract';
import { ChainId } from 'constant';

export interface NftInformation {
    hasBeenRedeemed: boolean;
    maturity: string;
    amount: string;
    cancelRedemptionFee: string;
    lpLockMaturity: string;
    lpLockToken: string;
    lpLockAmount: string;
    lpClaimed: boolean;
}

export default function useRedemptionQueueMethods() {
    const redemptionQueueContract = useRedemptionQueue();
    async function getNft(id: string) {
        if (!redemptionQueueContract) return;
        try {
            const res = await redemptionQueueContract.nftInformation(id);
            return res;
        } catch (error) {
            console.error('getNftInformation error');
            console.error(error);
            return undefined;
        }
    }

    async function estimateLpAmount(
        lpToken: string,
        reduceTime: number,
        redeemAmount: string,
    ) {
        if (!redemptionQueueContract) return;
        try {
            const res = await redemptionQueueContract.estimateLpAmount(
                lpToken,
                reduceTime,
                redeemAmount,
            );
            return res;
        } catch (error) {
            console.error('estimateLpAmount error');
            console.error(error);
            return undefined;
        }
    }

    async function getReduceTime(
        lpToken: string,
        lpAmount: string,
        redeemAmount: string,
    ) {
        if (!redemptionQueueContract) return;
        try {
            const res = await redemptionQueueContract.getReduceTime(
                lpToken,
                lpAmount,
                redeemAmount,
            );
            return res;
        } catch (error) {
            console.error('getReduceTime error');
            console.error(error);
            return undefined;
        }
    }

    async function getConfig(id: string) {
        if (!redemptionQueueContract) return;
        try {
            const res = await redemptionQueueContract.nftInformation(id);
            return res;
        } catch (error) {
            console.error('getNftInformation error');
            console.error(error);
            return undefined;
        }
    }

    return {
        redemptionQueueContract,
        getNft,

        estimateLpAmount,
        getReduceTime,
    };
}
