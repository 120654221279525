import { OuterLinkSVG, TipsSVG } from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
import Modal from 'components/Modal';
import { LockLP, LockLPConfig } from 'constant';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, TYPE } from 'theme';
import { ReactComponent as DownSVG } from 'assets/images/redeem/down.svg';
import { RowBetween } from 'components/Row';
import Button from 'components/Button';
import { Slider } from '@mui/material';
import { RedemptionQueueItem } from 'gql/graph/__generated__/request';

import user from 'stores/user';
import BigNumber from 'bignumber.js';
import useRedemptionQueueMethods from 'hooks/useRedemptionQueueMethods';
import { LockLPBufferTime } from './constant';
import { useContract } from 'hooks/useContract';
import ERC20_ABI from 'contracts/erc20.json';
import moment from 'moment';
import { getGraphRequestApi } from 'gql';
import useTokenApprove from 'hooks/useTokenApprove';
import Loading from 'components/Loading';
import { formatPrice } from 'utils';
import useSend from 'hooks/useSend';
import redemptionQueue from 'stores/redemptionQueue';
import { RedemptionConfig } from 'hooks/useConfigContract';

const Wrapper = styled.div`
    width: 500px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const LPWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: transparent;
    border-radius: 16px;
    padding-right: 8px;

    .linkSvg {
        width: 18px;
        height: 18px;
        margin-left: 8px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 14px;
        height: 14px;
        `};
    }

    svg {
        cursor: pointer;
    }

    svg.up {
        transform: rotate(180deg);
    }

    .empty {
        width: 40px;
        height: 40px;
    }
`;

const LPHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 220px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fit-content;
    gap: 10px;
    `};
    flex-shrink: 0;
    padding: 10px;
    img {
        width: 40px;
        height: 40px;
    }
`;

const StyledIputRow = styled(RowBetween)`
    height: 60px;
    background: #000000;
    border-radius: 16px;
    padding: 8px;

    &.error {
        border: 1px solid red;
    }

    input {
        background: transparent;
        color: #fff;
        border: none;
        flex-grow: 1;
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        [type='number'] {
            -moz-appearance: textfield; /* Firefox */
        }
    }

    button {
        color: #dca45c;
        font-size: 16px;
        flex-shrink: 0;
        cursor: pointer;
        border: none !important;
        height: 100% !important;
        border-radius: 8px !important;
    }
`;

interface LPProps extends LockLP {
    selected?: boolean;
    isOpenDropdown?: boolean;
    toggleDropdown?: () => void;
    onClick?: () => void;
}
function LP(lpconfig: LPProps) {
    return (
        <LPWrapper
            className="lp"
            onClick={() => {
                if (lpconfig.onClick) {
                    lpconfig.onClick();
                }
            }}
        >
            <LPHeader>
                <img src={lpconfig.logo} alt="" />
                <AutoColumn gap="4px">
                    <TYPE.main
                        color="#DCA45C"
                        fontSize={18}
                        mobileFz={14}
                        fontWeight={600}
                    >
                        {lpconfig.lpName}
                    </TYPE.main>
                    <TYPE.main color="#DCA45C" mobileFz={12}>
                        Pool/Token
                    </TYPE.main>
                </AutoColumn>
            </LPHeader>
            <TYPE.main
                color="#DCA45C"
                fontSize={18}
                mobileFz={14}
                fontWeight={600}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {lpconfig.platform}
                <OuterLinkSVG
                    className="linkSvg"
                    onClick={() => {
                        window.open(lpconfig.lpLink, '_blank');
                    }}
                />
            </TYPE.main>
            {lpconfig.selected ? (
                <DownSVG
                    className={lpconfig.isOpenDropdown ? 'up' : ''}
                    onClick={(e: any) => {
                        if (lpconfig.toggleDropdown) {
                            lpconfig.toggleDropdown();
                            e.stopPropagation();
                        }
                    }}
                />
            ) : (
                <div className="empty" />
            )}
        </LPWrapper>
    );
}

const LPContentWrapper = styled.div`
    position: relative;
    width: 100%;
    z-index: 8;
    padding: 10px;
    background-color: #553328;
    border-radius: 16px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
    `};
`;

const LPMenuWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 9999;
    background-color: #553328;
    padding: 10px;
    border-radius: 16px;
    .lp:hover {
        cursor: pointer;
        background: #412716;
    }
`;

const LockWrapper = styled.div`
    border-radius: 16px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    gap: 18px;
    z-index: 7;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 8px;
    padding: 12px;
    `};

    .MuiSlider-root {
        /* margin-top: 30px; */
        height: 10px;
        color: #dca45c;
        .MuiSlider-thumb {
            :hover {
                box-shadow: rgb(210 197 25 / 16%) 0px 0px 0px 8px;
            }
        }

        .MuiSlider-mark {
            width: 4px;
            height: 4px;
        }

        .MuiSlider-markLabel {
            color: #fff;

            .fixLeft {
                transform: translateX(70px);
            }

            .fixRight {
                transform: translateX(-70px);
            }
        }

        .MuiSlider-valueLabel {
            background-color: #000;
            border-radius: 4px;
        }
    }

    .addMoreLP {
        color: #dca45c;
        text-decoration: underline;
    }
    position: relative;
`;

const LoadingWrapper = styled.div`
    position: absolute;
    left: -10px;
    right: -10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: rgba(0, 0, 0, 0.2);

    backdrop-filter: blur(10px);
    z-index: 99;
    border-radius: 16px;
    flex-direction: column;
`;

const StyledButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    button {
        flex-shrink: 0;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 40%;
        `};
    }
`;

const Warning = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-items: flex-start;
    padding: 5px;
    border: 1px solid red;
    background-color: #fff;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    color: red;
    svg {
        width: 24px;
        height: 24px;
        margin-top: 2px;
    }
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    redemptionQueueItem: RedemptionQueueItem;
    isSpeedUpOutDate: boolean;
    chainTime?: number;
    redemptionConfig?: RedemptionConfig;
    updateNFTInformation: () => Promise<void>;
}

export default function LocklpModal({
    isOpen,
    onClose,
    redemptionQueueItem,
    isSpeedUpOutDate,
    chainTime,
    redemptionConfig,
    updateNFTInformation,
}: Props) {
    const { redemptionQueueContract, estimateLpAmount, getReduceTime } =
        useRedemptionQueueMethods();
    const [selectedLp, setSelectedLp] = useState<LockLP>(LockLPConfig[0]);
    const [isOpenLpMenu, setIsOpenLpMenu] = useState(false);
    const [inputNumber, setInputNumber] = useState<string>('');
    const inputAmount = useMemo(() => {
        return new BigNumber(inputNumber || 0);
    }, [inputNumber]);
    const [page, setPage] = useState(1);
    const [isPrepare, setIsPrepare] = useState(false);
    const [isCaculating, setIsCalculating] = useState(false);
    const [isLoadingPrice, setIsLoadingPrice] = useState(false);
    const [isLoadingEsTimatMaxLpAmount, setIsLoadingEsTimatMaxLpAmount] =
        useState(false);
    const [lpPrice, setLPPrice] = useState<BigNumber>(new BigNumber(0));
    const [actionInterval, setActionInterval] = useState<number>(0);
    const [isReduceTimeExpired, setIsReduceTimeExpired] = useState(false);
    const selectLpTokenInfo = useMemo(() => {
        return selectedLp.token[user.chainId];
    }, [user.chainId, selectedLp]);
    const lpTokenContract = useContract(
        selectedLp.token[user.chainId].address,
        ERC20_ABI,
    );
    const send = useSend();
    const [valuePercentage, setValuePercentage] = useState<number>(0);
    const [caculatedReduceTime, setCaculatedReduceTime] = useState<number>(0);
    const debounceRef = useRef<any>();
    const [estimateMaxLpAmount, setEstimateMaxLpAmount] = useState<BigNumber>(
        new BigNumber(0),
    );
    const {
        approved: isApprovedRedemptionQueue,
        approve: approveRedemptionQueue,
    } = useTokenApprove(
        selectedLp.token[user.chainId],
        redemptionQueueContract?.address || '',
        inputAmount,
    );

    const [userLpBalance, setUserLpBalance] = useState(new BigNumber(0));
    const [isLoadingUserBalance, setIsLoadingUserBalance] = useState(false);

    async function getUserLPBalance() {
        console.log('getUserLPBalance');
        if (!lpTokenContract || !lpTokenContract.address) return;
        setIsLoadingUserBalance(true);
        try {
            const res = await lpTokenContract.balanceOf(user.address);
            const decimals = await lpTokenContract.decimals();
            if (res) {
                setUserLpBalance(
                    new BigNumber(res.toString()).shiftedBy(-decimals),
                );
            }
        } catch (e) {
            console.error('getUserLPBalance error', lpTokenContract);
        }
        setIsLoadingUserBalance(false);
    }

    const newLockLPConfig = useMemo(() => {
        if (selectedLp) {
            return [
                selectedLp,
                ...LockLPConfig.filter((lp) => lp !== selectedLp),
            ];
        }
        return LockLPConfig;
    }, [selectedLp, LockLPConfig]);

    async function getReduceTimeFunc(amount: BigNumber) {
        if (!chainTime || !redemptionConfig) return;
        setIsCalculating(true);

        if (amount.isEqualTo(0)) {
            setCaculatedReduceTime(0);
        } else {
            setActionInterval(actionInterval + 1);
            try {
                const res = await getReduceTime(
                    lpTokenContract?.address || '',
                    amount.shiftedBy(selectLpTokenInfo.decimals).toFixed(0),
                    redemptionQueueItem.amount,
                );
                const leftTime =
                    Number(redemptionQueueItem.maturity) -
                    chainTime -
                    LockLPBufferTime;
                const maxReduceTime =
                    leftTime > redemptionConfig.minQueueLengthSecs
                        ? leftTime - redemptionConfig.minQueueLengthSecs
                        : 0;

                let maxCaculatedReduceTime = 0;
                console.log(
                    `nft#${
                        redemptionQueueItem.id
                    } getReduceTimeFunc: leftTime: ${leftTime}, maxReduceTime:${maxReduceTime}, return: ${res?.toNumber()}`,
                );
                if (res?.toNumber() > maxReduceTime) {
                    maxCaculatedReduceTime = maxReduceTime;
                } else {
                    maxCaculatedReduceTime = res?.toNumber();
                    // //  Accuracy issues: useMaxReduceTime => getMaxStakeAmount => useMaxStakeAmount => MaxReduceTime

                    // maxCaculatedReduceTime =
                    //     maxCaculatedReduceTime >
                    //     redemptionConfig.minQueueLengthSecs - 60
                    //         ? redemptionConfig.minQueueLengthSecs
                    //         : maxCaculatedReduceTime;
                }

                setCaculatedReduceTime(maxCaculatedReduceTime);
            } catch (e) {
                console.error('caculat reduce time error');
                console.error(e);
            }
            await getLPPrice();
        }

        setIsCalculating(false);
    }

    async function onSliderComplete(currentPercentage: number) {
        const caculatInputAmount = estimateMaxLpAmount
            .multipliedBy(currentPercentage)
            .dividedBy(100);
        setInputNumber(caculatInputAmount.toFixed());
        await getReduceTimeFunc(caculatInputAmount);
    }

    function handleInput(value: string) {
        setInputNumber(value);
        let percentage = 0;
        if (!value || Number(value) === 0) {
            percentage = 0;
        } else if (estimateMaxLpAmount.isLessThanOrEqualTo(value)) {
            percentage = 100;
        } else {
            percentage = (Number(value) / estimateMaxLpAmount.toNumber()) * 100;
        }
        setValuePercentage(percentage);
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }
        debounceRef.current = setTimeout(() => {
            getReduceTimeFunc(new BigNumber(value || 0));
        }, 1000);
    }

    function formatSliderLabel(value: number) {
        const amount =
            value === 100 && inputAmount.isGreaterThan(estimateMaxLpAmount)
                ? inputAmount
                : estimateMaxLpAmount;

        return `$${formatPrice(
            amount
                .multipliedBy(value)
                .multipliedBy(lpPrice)
                .dividedBy(100)
                .toFixed(),
        )}`;
    }

    async function getEstimateMaxLpAmount() {
        console.log('getEstimateMaxLpAmount');
        if (
            redemptionQueueContract &&
            Number(redemptionQueueItem.amount) &&
            redemptionConfig &&
            selectLpTokenInfo.address
        ) {
            setIsLoadingEsTimatMaxLpAmount(true);
            const leftTime =
                Number(redemptionQueueItem.maturity) -
                Math.floor(new Date().getTime() / 1000) -
                LockLPBufferTime;

            const maxSpeedUpTime =
                leftTime > redemptionConfig.minQueueLengthSecs
                    ? leftTime - redemptionConfig.minQueueLengthSecs
                    : 0;
            const duration = moment.duration(maxSpeedUpTime * 1000);
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();

            const res = await estimateLpAmount(
                selectLpTokenInfo.address,
                maxSpeedUpTime,
                redemptionQueueItem.amount,
            );

            console.log(
                `nft#${
                    redemptionQueueItem.id
                } getEstimateMaxLpAmount: ${maxSpeedUpTime}s = ${days} days, ${hours} hrs, ${minutes} mins, bufferTime=${LockLPBufferTime}s, return: ${res?.lpAmount?.toString()}`,
            );

            if (res?.lpAmount) {
                setEstimateMaxLpAmount(
                    new BigNumber(res?.lpAmount.toString()).shiftedBy(
                        -selectLpTokenInfo.decimals,
                    ),
                );
            }
            setIsLoadingEsTimatMaxLpAmount(false);
        }
    }

    async function getLPPrice() {
        console.log('getLPPrice');
        if (!selectLpTokenInfo.address) return;
        // if (withLoading) {
        setIsLoadingPrice(true);
        // }
        try {
            const res = await getGraphRequestApi().lpPrice({
                lp: selectLpTokenInfo.address,
            });
            setLPPrice(new BigNumber(res?.lpPrice.toString()).shiftedBy(-18));
        } catch (error) {
            console.error('getPrice error', error);
        }

        // if (withLoading) {
        setIsLoadingPrice(false);
        // }
    }

    async function prepareData() {
        setIsPrepare(true);
        try {
            const p = Promise.all([
                getEstimateMaxLpAmount(),
                getLPPrice(),
                getUserLPBalance(),
            ]);
            await p;
        } catch (e) {
            console.error('prepareData error');
            console.error(e);
        }
        setIsPrepare(false);
    }
    const isReadyToPrepare = useMemo(() => {
        return !!(
            selectLpTokenInfo.address &&
            redemptionQueueContract &&
            redemptionQueueItem.amount &&
            redemptionConfig
        );
    }, [redemptionQueueContract, redemptionQueueItem.amount, redemptionConfig]);

    useEffect(() => {
        prepareData();
    }, [lpTokenContract?.address, isReadyToPrepare]);

    useEffect(() => {
        let interval: any;

        if (actionInterval) {
            interval = setTimeout(() => {
                setIsReduceTimeExpired(true);
            }, 120000);
        }

        return () => {
            if (interval) {
                clearTimeout(interval);
                interval = null;
            }
        };
    }, [actionInterval]);

    const marks = useMemo(() => {
        const defaultMarks: any = [];
        if (
            isLoadingEsTimatMaxLpAmount ||
            lpPrice.isEqualTo(0) ||
            userLpBalance.isEqualTo(0) ||
            estimateMaxLpAmount.isEqualTo(0)
        ) {
            return defaultMarks;
        }
        const percent = userLpBalance
            .dividedBy(estimateMaxLpAmount)
            .multipliedBy(100)
            .toNumber();
        if (percent >= 100) return defaultMarks;

        return [
            ...defaultMarks,
            {
                value: percent,

                label: (
                    <TYPE.main
                        textAlign={
                            percent < 10
                                ? 'left'
                                : percent > 80
                                ? 'right'
                                : 'center'
                        }
                        className={
                            percent < 10
                                ? 'fixLeft'
                                : percent > 80
                                ? 'fixRight'
                                : ''
                        }
                        fontSize={14}
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                            handleInput(userLpBalance.toFixed());
                        }}
                    >
                        $ {userLpBalance.multipliedBy(lpPrice).toFixed(2)}
                        <br />
                        MAX. STAKE LP Value
                    </TYPE.main>
                ),
            },
        ];
    }, [
        userLpBalance,
        lpPrice,
        isLoadingEsTimatMaxLpAmount,
        estimateMaxLpAmount,
    ]);

    async function reduceRedemptionMaturity() {
        if (!redemptionQueueContract) return;

        await send({
            contract: redemptionQueueContract,
            method: 'reduceRedemptionMaturity',
            params: [
                redemptionQueueItem.id,
                selectLpTokenInfo.address,
                inputAmount.shiftedBy(selectLpTokenInfo.decimals).toFixed(0),
            ],
            pendingText: `Staking ${inputAmount.toFixed()} ENKI LP (${
                selectLpTokenInfo.name
            }) to speed up the redemption #${redemptionQueueItem.id}`,
            summary: `Staked ${inputAmount.toFixed()} ENKI LP (${
                selectLpTokenInfo.name
            }) to speed up the redemption #${redemptionQueueItem.id}`,
            onSuccess: async () => {
                await redemptionQueue.getNftList();
                await updateNFTInformation();
                onClose();
            },
        });
    }

    const isOverInputAmount = useMemo(() => {
        return inputAmount.isGreaterThan(estimateMaxLpAmount);
    }, [inputAmount, estimateMaxLpAmount]);

    const isInsufficientBalance = useMemo(() => {
        return inputAmount.isGreaterThan(userLpBalance);
    }, [userLpBalance, inputAmount]);

    const isInputError = useMemo(() => {
        return isOverInputAmount || isInsufficientBalance;
    }, [isInsufficientBalance, isOverInputAmount]);

    const caculatReduceTimeStr = useMemo(() => {
        if (!caculatedReduceTime) return '-';

        const duration = moment.duration(
            caculatedReduceTime > 0 ? caculatedReduceTime * 1000 : 0,
        );
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        return `${days} days, ${hours} hrs, ${minutes} mins`;
    }, [caculatedReduceTime]);

    const lockTime = useMemo(() => {
        if (!redemptionConfig?.reduceMaturityStakeSecs) return '-';
        const duration = moment.duration(
            redemptionConfig.reduceMaturityStakeSecs * 1000,
        );
        const days = duration.days();
        if (days) {
            return `${days} days`;
        }
        const hours = duration.hours();

        if (hours) {
            return `${hours} hours`;
        }
        const minutes = duration.minutes();
        if (minutes) {
            return `${minutes} minutes`;
        }
        return '-';
    }, [redemptionConfig?.reduceMaturityStakeSecs]);

    async function refresh() {
        await prepareData();
        await getReduceTimeFunc(inputAmount);
        setIsReduceTimeExpired(false);
    }

    function handleMax() {
        const maxAmount = estimateMaxLpAmount.isGreaterThan(userLpBalance)
            ? userLpBalance
            : estimateMaxLpAmount;

        handleInput(maxAmount.toFixed());
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Wrapper>
                <TYPE.main
                    fontSize={20}
                    fontWeight={600}
                    textAlign={'center'}
                    paddingBottom={18}
                >
                    Stake ENKI LP to speed up
                </TYPE.main>
                {page === 1 && (
                    <AutoColumn gap="18px" justify="center">
                        <LPContentWrapper>
                            <LP
                                {...selectedLp}
                                selected
                                isOpenDropdown={isOpenLpMenu}
                                toggleDropdown={() => {
                                    setIsOpenLpMenu(!isOpenLpMenu);
                                }}
                            />
                            {isOpenLpMenu && (
                                <LPMenuWrapper>
                                    {newLockLPConfig.map((lp) => (
                                        <LP
                                            key={lp.lpName}
                                            {...lp}
                                            selected={selectedLp === lp}
                                            isOpenDropdown={isOpenLpMenu}
                                            toggleDropdown={() => {
                                                setIsOpenLpMenu(!isOpenLpMenu);
                                            }}
                                            onClick={() => {
                                                setSelectedLp(lp);
                                                setIsOpenLpMenu(false);
                                                if (lp !== selectedLp) {
                                                    handleInput('');
                                                }
                                            }}
                                        />
                                    ))}
                                </LPMenuWrapper>
                            )}
                        </LPContentWrapper>
                        <LockWrapper>
                            {isSpeedUpOutDate && (
                                <LoadingWrapper>
                                    <TYPE.main
                                        color={'mainColor'}
                                        fontWeight={600}
                                    >
                                        Data expired, no need to lockup ENKI LP.
                                    </TYPE.main>
                                    <Button size="small" onClick={onClose}>
                                        Close
                                    </Button>
                                </LoadingWrapper>
                            )}
                            {isPrepare && !isReduceTimeExpired && (
                                <LoadingWrapper>
                                    <Loading style={{ color: '#DCA45C' }} />
                                    <TYPE.main
                                        color={'mainColor'}
                                        fontWeight={600}
                                    >
                                        Loading
                                    </TYPE.main>
                                </LoadingWrapper>
                            )}
                            {!isSpeedUpOutDate && isReduceTimeExpired && (
                                <LoadingWrapper>
                                    <TYPE.main
                                        color={'mainColor'}
                                        fontWeight={600}
                                    >
                                        Data expired, please refresh
                                    </TYPE.main>
                                    <Button size="small" onClick={refresh}>
                                        Refresh
                                    </Button>
                                </LoadingWrapper>
                            )}

                            <TYPE.main
                                fontSize={18}
                                mobileFz={16}
                                fontWeight={600}
                            >
                                AMOUNT
                            </TYPE.main>
                            <StyledIputRow
                                className={isInputError ? 'error' : ''}
                            >
                                <input
                                    placeholder="Enter Amount"
                                    id="lpinput"
                                    value={inputNumber}
                                    type="number"
                                    // min={0.000000000000000001}
                                    // step={0.000000000000000001}
                                    onChange={(e) => {
                                        if (Number(e.target.value) < 0) {
                                            handleInput('');
                                        } else {
                                            handleInput(e.target.value);
                                        }
                                    }}
                                />
                                <Button
                                    size="small"
                                    disabled={userLpBalance.isEqualTo(0)}
                                    onClick={handleMax}
                                >
                                    Max
                                </Button>
                            </StyledIputRow>
                            <RowBetween>
                                <div />
                                {isInputError ? (
                                    <>
                                        {isOverInputAmount ? (
                                            <TYPE.main color="red">
                                                You can only stake{' '}
                                                {estimateMaxLpAmount.toFixed()}{' '}
                                                LP at most.
                                            </TYPE.main>
                                        ) : isInsufficientBalance ? (
                                            <TYPE.main color="red">
                                                Insufficient balance,{' '}
                                                <Link
                                                    href={selectedLp.lpLink}
                                                    className="addMoreLP"
                                                >
                                                    Add more LP?
                                                </Link>
                                            </TYPE.main>
                                        ) : null}
                                    </>
                                ) : (
                                    <TYPE.main>
                                        LP balance: {userLpBalance.toFixed(4)}{' '}
                                        ≈${' '}
                                        {formatPrice(
                                            userLpBalance
                                                .multipliedBy(lpPrice)
                                                .toNumber(),
                                        )}
                                    </TYPE.main>
                                )}
                            </RowBetween>
                            <AutoColumn gap="4px" style={{ width: '100%' }}>
                                <RowBetween>
                                    <TYPE.main
                                        onClick={() => {
                                            handleInput('');
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        $0
                                    </TYPE.main>
                                    <div>
                                        {inputAmount.isEqualTo(0) && (
                                            <TYPE.main
                                                fontSize={14}
                                                color="mainColor"
                                                textAlign={'center'}
                                            >
                                                Drag to select LP amount
                                            </TYPE.main>
                                        )}
                                    </div>
                                </RowBetween>
                                <Slider
                                    aria-label="Default"
                                    // getAriaValueText={valuetext}
                                    value={valuePercentage}
                                    onChange={(e, value) => {
                                        setValuePercentage(Number(value));
                                    }}
                                    onChangeCommitted={(e, value) => {
                                        onSliderComplete(value as number);
                                    }}
                                    marks={marks}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={formatSliderLabel}
                                />
                            </AutoColumn>
                            <TYPE.main
                                fontSize={18}
                                mobileFz={16}
                                fontWeight={600}
                                style={{ marginTop: '20px' }}
                            >
                                REDUCE TIME
                            </TYPE.main>
                            <RowBetween style={{ flexWrap: 'wrap' }}>
                                <TYPE.main mobileFz={14}>
                                    EST. REDUCED WAITING TIME
                                </TYPE.main>
                                <TYPE.main mobileFz={14}>
                                    {isCaculating
                                        ? 'Caculating...'
                                        : caculatReduceTimeStr}
                                </TYPE.main>
                            </RowBetween>
                        </LockWrapper>
                        <Warning>
                            <TipsSVG />
                            Each redeem ticket can only be accelerated ONCE by
                            staking LP, with {lockTime} lock.
                        </Warning>
                        <StyledButtonGroup>
                            <Button type="outlineWhite" onClick={onClose}>
                                Cancel
                            </Button>
                            {isApprovedRedemptionQueue ? (
                                <Button
                                    disabled={
                                        inputAmount.isEqualTo(0) ||
                                        isInputError ||
                                        isPrepare ||
                                        isReduceTimeExpired
                                    }
                                    onClick={() => {
                                        setPage(2);
                                    }}
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    disabled={
                                        inputAmount.isEqualTo(0) ||
                                        isInputError ||
                                        isPrepare
                                    }
                                    onClick={approveRedemptionQueue}
                                >
                                    Approve
                                </Button>
                            )}
                        </StyledButtonGroup>
                    </AutoColumn>
                )}

                {page === 2 && (
                    <AutoColumn gap="18px">
                        <TYPE.main
                            fontSize={18}
                            fontWeight={600}
                            style={{ marginTop: '20px' }}
                        >
                            Lock LP
                        </TYPE.main>
                        <LPContentWrapper>
                            <LPWrapper className="lp">
                                <LPHeader>
                                    <img src={selectedLp.logo} alt="" />
                                    <AutoColumn gap="4px">
                                        <TYPE.main
                                            color="#DCA45C"
                                            fontSize={16}
                                            mobileFz={14}
                                            fontWeight={600}
                                        >
                                            {selectedLp.lpName}
                                        </TYPE.main>
                                        <TYPE.main
                                            color="#DCA45C"
                                            mobileFz={12}
                                        >
                                            Pool/Token
                                        </TYPE.main>
                                    </AutoColumn>
                                </LPHeader>
                                <AutoColumn gap="4px" justify="flex-end">
                                    <TYPE.main
                                        fontSize={16}
                                        fontWeight={600}
                                        mobileFz={12}
                                    >
                                        {inputAmount.toFixed()}
                                    </TYPE.main>
                                    <TYPE.main
                                        color="#DCA45C"
                                        fontSize={14}
                                        mobileFz={12}
                                    >
                                        LOCK LP VAULE=$
                                        {formatPrice(
                                            inputAmount
                                                .multipliedBy(lpPrice)
                                                .toNumber(),
                                        )}
                                    </TYPE.main>
                                </AutoColumn>
                            </LPWrapper>
                        </LPContentWrapper>
                        <TYPE.main
                            fontSize={18}
                            fontWeight={600}
                            style={{ marginTop: '20px' }}
                        >
                            REDUCE TIME
                        </TYPE.main>
                        <RowBetween style={{ flexWrap: 'wrap' }}>
                            <TYPE.main mobileFz={14}>
                                EST. REDUCED WAITING TIME
                            </TYPE.main>
                            <TYPE.main mobileFz={14}>
                                {isCaculating
                                    ? 'Caculating...'
                                    : caculatReduceTimeStr}
                            </TYPE.main>
                        </RowBetween>
                        <Warning>
                            <TipsSVG />
                            Each redeem ticket can only be accelerated ONCE by
                            staking LP, with {lockTime} lock.
                        </Warning>
                        <StyledButtonGroup>
                            <Button
                                type="outlineWhite"
                                onClick={() => {
                                    setPage(1);
                                }}
                            >
                                Back
                            </Button>
                            <Button onClick={reduceRedemptionMaturity}>
                                Confirm
                            </Button>
                        </StyledButtonGroup>
                    </AutoColumn>
                )}
            </Wrapper>
        </Modal>
    );
}
