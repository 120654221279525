import { AutoColumn } from 'components/Column';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { CardInfo } from '../type';
import Row, { RowBetween } from 'components/Row';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from 'components/Button';
import useDistributor from 'hooks/useDistributorContract';
import { observer } from 'mobx-react-lite';
import user from 'stores/user';
import useSend from 'hooks/useSend';
import ShareModal from './shareModal';
import storage from 'utils/storage';
import BigNumber from 'bignumber.js';
import ClaimENKISuccessModal from './claimENKISuccessModal';
import { TokenParams } from 'hooks/useAddToken';
import { useContract } from 'hooks/useContract';
import { erc20Abi } from 'viem';
import { Logo } from 'common-helpers/img';
import { Distributor } from 'gql/distributor/__generated__/request';
const Card = styled.div`
    height: 445px;
    padding: 32px;
    width: 900px;
    background-color: #fff;
    border-radius: 24px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: unset;
    padding: 24px;
    margin-top: 40px;
    `};
`;

const CardContent = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-top: 32px;
    gap: 32px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 12px;
    flex-direction: column;
    margin-top: 12px;
    `};
`;

const CardImg = styled.div`
    width: 286px;
    height: 286px;
    background: linear-gradient(180deg, #10100f 0%, #3e3e3c 100%);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 300px;
    }
`;

const CardRightContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 12px;
    `};

    .cardInfoTitle {
        ${({ theme }) => theme.mediaWidth.upToSmall`
        display: flex;
        flex-direction: row;
        gap: 8px;
        `};
    }
`;

const CardENKIButton = styled.div`
    width: 66px;
    height: 28px;
    border-radius: 14px;
    border: 1px solid #dca45c;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        height: 62%;
    }
`;

const Fbutton = styled(Button)`
    width: 200px;
    background: rgba(220, 164, 92, 1) !important;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fit-content;
    flex-shrink: 0;
    padding: 10px !important;
    `};
`;

const MediaWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-left: 24px;
    flex-direction: row;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    justify-content: flex-start;
    `};
`;

const MediaImg = styled.img`
    width: 40px;
    height: 40px;
`;

const LinkRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

const OuterLink = styled.a`
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #000;
    img {
        width: 10px;
        height: 10px;
    }
`;

interface Props {
    config: CardInfo;
    distributor?: Distributor;
    index: number;
}

const defaultImg = [
    require('../img/nft_1.png'),
    require('../img/nft_2.png'),
    require('../img/nft_3.png'),
];

const storageKey = 'enki-fantasy-check-eligibility';
function DistributorCard({ config, distributor, index }: Props) {
    const [isOpenClaimedNftSuccess, setIsOpenClaimedNftSuccess] =
        useState(false);
    const [isOpenClaimComingSoon, setIsOpenClaimComingSoon] = useState(false);
    const [isOpenClaimedENKI, setIsOpenClaimedENKI] = useState(false);
    const isComingSoon = !distributor;
    const {
        isUserHasAirdrop,
        getUserMerkleLeaf,
        isClaimedNFT,
        isClaimedENKI,
        nftToknURI,
        userMerkleLeaf,
        distributorContract,
        nftTokenId,
        getWithdrawEnabledBefore,
        claimCount,
        getClaimCount,
        setClaimableENKIAmount,
        setIsClaimedNft,
        claimableENKIAmount,
    } = useDistributor(distributor);
    const send = useSend();
    const tokenContract = useContract(distributor?.tokenAddress, erc20Abi);
    const [isOpenClaim, setIsOpenClaim] = useState(false);

    async function checkEligibility() {
        const storageRes = storage.get(storageKey) || {};
        const key = `${user.chainId}-${distributor?.distributorAddress}-${user.address}`;
        if (!storageRes[key] && user.address) {
            storageRes[key] = true;
            storage.set(storageKey, storageRes);
        }
        await getUserMerkleLeaf();
    }

    async function claimNFT() {
        if (
            !distributorContract ||
            !distributor ||
            !userMerkleLeaf ||
            !user.address
        )
            return;
        await send({
            contract: distributorContract,
            method: 'claim',
            params: [
                userMerkleLeaf?.index,
                user.address,
                userMerkleLeaf?.amount,
                userMerkleLeaf?.proof,
            ],
            pendingText: 'Claiming',
            summary: `Claiming ${distributor.nftName} NFT`,
            showSuccess: false,
            onSuccess() {
                setIsOpenClaimedNftSuccess(true);
                getClaimCount();
                setIsClaimedNft(true);
            },
        });
    }

    async function withDrawENKITokenWithNFT() {
        if (
            nftTokenId === undefined ||
            !distributorContract ||
            !distributor ||
            !user.address ||
            !tokenContract
        )
            return;
        const withdrawEnableBefore = await getWithdrawEnabledBefore();
        console.log('withdrawEnableBefore', withdrawEnableBefore);
        console.log('nftTokenId', nftTokenId);

        try {
            const distributorBalance = await tokenContract.balanceOf(
                distributor.distributorAddress,
            );
            console.log('distributorBalance', distributorBalance?.toString());
            if (
                !!distributorBalance &&
                new BigNumber(distributorBalance?.toString()).isGreaterThan(
                    0,
                ) &&
                nftTokenId < withdrawEnableBefore
            ) {
                await send({
                    contract: distributorContract,
                    method: 'withdraw',
                    params: [nftTokenId],
                    pendingText: 'Withdrawing',
                    summary: `Withdrew ENKI with ${distributor.nftName} NFT`,
                    showSuccess: false,
                    onSuccess() {
                        setClaimableENKIAmount(new BigNumber(0));
                        setIsOpenClaimedENKI(true);
                        // getENKIClaimableAmounts();
                    },
                });
            } else {
                setIsOpenClaimComingSoon(true);
                return;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function getIsOpenClaim() {
        if (tokenContract && distributor && nftTokenId && distributorContract) {
            const withdrawEnableBefore = await getWithdrawEnabledBefore();
            const distributorBalance = await tokenContract.balanceOf(
                distributor.distributorAddress,
            );
            setIsOpenClaim(
                !!distributorBalance &&
                    new BigNumber(distributorBalance?.toString()).isGreaterThan(
                        0,
                    ) &&
                    nftTokenId < withdrawEnableBefore,
            );
            return;
        }

        setIsOpenClaim(false);
        return;
    }

    useEffect(() => {
        if (distributor?.distributorAddress && user.address) {
            const storageRes = storage.get(storageKey) || {};
            const key = `${user.chainId}-${distributor?.distributorAddress}-${user.address}`;
            if (storageRes[key]) {
                getUserMerkleLeaf();
            }
        }
    }, [user.chainId, distributor, user.address]);

    const token = useMemo(() => {
        return {
            address: distributor?.tokenAddress,
            name: 'ENKI Token',
            symbol: 'ENKI',
            decimals: 18,
        } as TokenParams;
    }, [distributor]);

    const claimAmount = useMemo(() => {
        return new BigNumber(userMerkleLeaf?.amount || 0).shiftedBy(-18);
    }, [userMerkleLeaf]);

    useEffect(() => {
        getIsOpenClaim();
    }, [distributorContract, tokenContract, distributor, nftTokenId]);

    return (
        <Card>
            <AutoColumn>
                <TYPE.main color="#000">{config.title}</TYPE.main>
                <TYPE.main
                    fontSize={20}
                    fontWeight={600}
                    color="rgba(218, 162, 91, 1)"
                >
                    {config.desc}
                </TYPE.main>
            </AutoColumn>
            <CardContent>
                <CardImg>
                    <img src={nftToknURI || defaultImg[index]} alt="" />
                </CardImg>
                <CardRightContent>
                    <AutoColumn gap="8px" className="cardInfoTitle">
                        <TYPE.main
                            color="#000"
                            fontSize={28}
                            fontWeight={600}
                            mobileFz={16}
                        >
                            {distributor?.nftName || config.infoTitle}
                        </TYPE.main>
                        <CardENKIButton>
                            <Logo />
                        </CardENKIButton>
                    </AutoColumn>
                    <RowBetween>
                        <AutoColumn gap="8px">
                            <TYPE.main
                                fontSize={28}
                                mobileFz={16}
                                fontWeight={600}
                                color="#000"
                            >
                                {claimCount}
                            </TYPE.main>
                            <TYPE.main fontSize={1} color="#000">
                                NFTs Claimed
                            </TYPE.main>
                        </AutoColumn>
                        <AutoColumn gap="8px">
                            <TYPE.main
                                fontSize={28}
                                mobileFz={16}
                                fontWeight={600}
                                color="#000"
                            >
                                ERC-721
                            </TYPE.main>
                            <TYPE.main fontSize={1} color="#000">
                                Standard
                            </TYPE.main>
                        </AutoColumn>
                        <AutoColumn gap="8px">
                            <TYPE.main
                                fontSize={28}
                                mobileFz={16}
                                fontWeight={600}
                                color="#000"
                            >
                                Andromeda
                            </TYPE.main>
                            <TYPE.main fontSize={1} color="#000">
                                Network
                            </TYPE.main>
                        </AutoColumn>
                    </RowBetween>
                    <AutoColumn
                        gap="16px"
                        style={{
                            width: '100%',
                            marginTop: '10px',
                        }}
                    >
                        {isUserHasAirdrop === -1 && (
                            <>
                                <Fbutton
                                    showLoading
                                    disabled={isComingSoon}
                                    onClick={checkEligibility}
                                    auth
                                >
                                    {isComingSoon
                                        ? 'Coming Soon'
                                        : 'Check Eligibility'}
                                </Fbutton>
                                {/* <TYPE.desc mobileFz={14}>
                                    Please claim NFT first,which is a voucher
                                    for receiving airdrop
                                </TYPE.desc> */}
                            </>
                        )}

                        {isUserHasAirdrop === 1 &&
                            (isClaimedENKI ? (
                                <>
                                    <TYPE.main
                                        fontSize={24}
                                        color="#DAA25B"
                                        fontWeight={600}
                                    >
                                        Got {claimAmount.toFixed(4)} ENKI
                                    </TYPE.main>
                                    <LinkRow>
                                        <OuterLink
                                            href="https://netswap.io/#/swap?outputCurrency=0x096A84536ab84E68ee210561FFD3A038E79736F1"
                                            target="_blank"
                                        >
                                            Get more ENKI
                                            <img
                                                src={require('./imgs/outerlinlk.png')}
                                                alt=""
                                            />
                                        </OuterLink>
                                        <OuterLink
                                            href="https://netswap.io/#/add/0x096a84536ab84e68ee210561ffd3a038e79736f1/0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000"
                                            target="_blank"
                                        >
                                            LP ENKI/Metis
                                            <img
                                                src={require('./imgs/outerlinlk.png')}
                                                alt=""
                                            />
                                        </OuterLink>
                                        <OuterLink
                                            href="https://netswap.io/#/add/0x096a84536ab84e68ee210561ffd3a038e79736f1/0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278"
                                            target="_blank"
                                        >
                                            LP ENKI/NETT
                                            <img
                                                src={require('./imgs/outerlinlk.png')}
                                                alt=""
                                            />
                                        </OuterLink>
                                        <OuterLink
                                            href="https://app.hercules.exchange/liquidity/?token1=0x096a84536ab84e68ee210561ffd3a038e79736f1&token2=0xEA32A96608495e54156Ae48931A7c20f0dcc1a21&type=v2&position=lp"
                                            target="_blank"
                                        >
                                            LP ENKI/m.USDC
                                            <img
                                                src={require('./imgs/outerlinlk.png')}
                                                alt=""
                                            />
                                        </OuterLink>
                                    </LinkRow>
                                </>
                            ) : (
                                <RowBetween style={{ width: '100%' }}>
                                    <Fbutton
                                        disabled={isClaimedNFT}
                                        onClick={claimNFT}
                                    >
                                        {isClaimedNFT
                                            ? 'NFT claimed'
                                            : 'Claim NFT'}
                                    </Fbutton>
                                    <Row
                                        style={{
                                            width: 'fit-content',
                                            color: '#DCA45C',
                                        }}
                                    >
                                        <KeyboardArrowRightIcon />
                                        <KeyboardArrowRightIcon
                                            style={{
                                                marginLeft: '-15px',
                                            }}
                                        />
                                    </Row>
                                    <Fbutton
                                        disabled={
                                            isClaimedENKI || !isClaimedNFT
                                        }
                                        onClick={withDrawENKITokenWithNFT}
                                    >
                                        {isClaimedENKI
                                            ? 'ENKI claimed'
                                            : 'Claim Tokens'}
                                    </Fbutton>
                                </RowBetween>
                            ))}

                        {isUserHasAirdrop === 0 && (
                            <>
                                <TYPE.desc mobileFz={14} color="#000">
                                    {`Regrettably, you're not eligible to claim the reward at this moment. Please stay tuned to our social media channels for upcoming opportunities.`}
                                </TYPE.desc>
                                <Row>
                                    <TYPE.main color="#000">
                                        Follow us:
                                    </TYPE.main>
                                    <MediaWrapper>
                                        <a
                                            href="https://twitter.com/ENKIProtocol"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <MediaImg
                                                src={require('assets/images/media/ic_Twitter.png')}
                                            />
                                        </a>
                                        <a
                                            href="https://t.me/enkixyz"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <MediaImg
                                                src={require('assets/images/media/ic_telegram.png')}
                                            />
                                        </a>
                                        <a
                                            href="https://medium.com/@ENKIProtocol"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <MediaImg
                                                src={require('assets/images/media/ic_medium.png')}
                                            />
                                        </a>
                                    </MediaWrapper>
                                </Row>
                            </>
                        )}
                    </AutoColumn>
                </CardRightContent>
            </CardContent>
            <ShareModal
                open={isOpenClaimedNftSuccess}
                img={nftToknURI || defaultImg[index]}
                text={`Congratulations on successfully claiming your NFT!${
                    isOpenClaim
                        ? ''
                        : ' Stay alert for the upcoming token claim phase.'
                }`}
                isOpenClaim={isOpenClaim}
                type="share"
                onClose={() => {
                    setIsOpenClaimedNftSuccess(false);
                }}
            />
            <ShareModal
                open={isOpenClaimComingSoon}
                img={require('../img/enki-banner.png')}
                text="The token claim phase is approaching. Stay updated by following our social media for further details."
                type="follow"
                onClose={() => {
                    setIsOpenClaimComingSoon(false);
                }}
            />
            <ClaimENKISuccessModal
                open={isOpenClaimedENKI}
                token={token}
                amount={claimAmount}
                onClose={() => {
                    setIsOpenClaimedENKI(false);
                }}
            />
        </Card>
    );
}
export default observer(DistributorCard);
