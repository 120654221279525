import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import { useConstantToken } from './useConstantToken';
import BigNumber from 'bignumber.js';
import { getGraphRequestApi } from 'gql';
import { SequencerInfo } from 'gql/graph/__generated__/request';

export default function useSequencerInfo() {
    const [sequencerInfoRes, setSequencerInfoRes] = useState<SequencerInfo>();
    const { eMetis } = useConstantToken();
    async function getSequencerInfoFunc() {
        try {
            const res = await getGraphRequestApi(user.chainId).sequencerInfo();
            if (res) {
                setSequencerInfoRes(res.sequencerInfo);
            }
        } catch (e) {
            console.error('getSequencerInfoFunc error');
            console.error(e);
        }
    }

    useEffect(() => {
        getSequencerInfoFunc();
        const interval = setInterval(() => {
            getSequencerInfoFunc();
        }, 60000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [user.chainId]);

    const sequencerInfo = useMemo(() => {
        if (!sequencerInfoRes) return undefined;
        return {
            apr: new BigNumber(sequencerInfoRes.apr)
                .shiftedBy(-eMetis.decimals)
                .toNumber(),
            l1Staked: new BigNumber(sequencerInfoRes.l1Staked).shiftedBy(-18),
            syncingToL1: new BigNumber(sequencerInfoRes.syncingToL1).shiftedBy(
                -18,
            ),
            totalRewards: new BigNumber(
                sequencerInfoRes.totalRewards,
            ).shiftedBy(-18),
        };
    }, [sequencerInfoRes, eMetis]);

    return {
        sequencerInfo,
        getSequencerInfoFunc,
    };
}
