import { ChainId } from 'constant';
import { getDistributorApi } from 'gql';
import { Distributor } from 'gql/distributor/__generated__/request';

import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import storage from 'utils/storage';

const storageKey = 'enki-fantasy-distributors';
// const initRes = storage.get(storageKey) || {};

export default function useGetDistributor() {
    const [allDistributors, setAllDistributors] = useState<{
        [ChainId.mainnet]: Distributor[];
        [ChainId.testnet]: Distributor[];
    }>();

    async function getDistributorFunc() {
        try {
            const res = await getDistributorApi().distributors();
            if (res.distributors) {
                const storageRes = storage.get(storageKey) || {};
                storageRes[`${user.chainId}`] = res.distributors;
                storage.set(storageKey, storageRes);
                setAllDistributors({
                    ...storageRes,
                });
            } else {
                console.error('get empty distributors');
            }
        } catch (e) {
            console.error('getDistributor error');
        }
    }

    useEffect(() => {
        getDistributorFunc();
    }, [user.chainId]);

    const distributors = useMemo(() => {
        return allDistributors ? allDistributors[user.chainId] : [];
    }, [user.chainId, allDistributors]);

    return {
        distributors,
    };
}
