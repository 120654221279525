import { ChainId } from 'constant';
import { getGraphRequestApi } from 'gql';
import { ContractsQuery } from 'gql/graph/__generated__/request';
import { useEffect } from 'react';
import contracts from 'stores/contracts';
import simpleDialog from 'stores/simpleDialog';
import user from 'stores/user';

export default function useGetContractByGraph():
    | ContractsQuery['contracts']
    | undefined {
    async function getContractsFunc() {
        try {
            const res = await getGraphRequestApi().contracts();

            if (res.contracts) {
                contracts.setContract(res.contracts);
            } else {
                console.error(`get deployed info error`);
            }
        } catch (e) {
            simpleDialog.error({
                title: 'Error',
                text: 'There are some issues with your internet connection, please check and try again later.',
            });
        }
    }

    useEffect(() => {
        getContractsFunc();
    }, [user.chainId]);

    return contracts.contracts;
}
