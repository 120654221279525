export enum Client {
    graph = 'graph',
    exchangeSubgraph = 'exchangeSubgraph',
    distributor = 'distributor',
}

export interface ReferralPoints {
    points: number;
    referrals: number;
    stakedByInvitees: number;
}
