import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Row from 'components/Row';
import Column, { ColumnCenter } from 'components/Column';
import { ContentTitle } from 'components/Page/DataComponents';
import PageWrapper from 'components/Page/PageWrapper';
import DataWrapper from 'components/Page/DataWrapper';
import Button from 'components/Button';
import inviterNFTImg from 'assets/images/nft_Inviter NFT.png';
import copyLinkImg from 'assets/images/ic_copy.png';
import { TYPE } from 'theme';
import { ComingSoonWrapper, ComingSoonImg } from 'components/Page/PageContent';
import user from 'stores/user';
import { ADDRESS_ZERO } from 'constant';
import { formatNumber, formatPoints } from 'utils';
import { compressAddress } from 'utils/compress';
// import useInviteNFTContract from 'hooks/useInviterNFT';
import { useInviterNFT } from 'hooks/useContract';
import useSend from 'hooks/useSend';
import { useConstantToken } from 'hooks/useConstantToken';
import useTokenBalance from 'hooks/useTokenBalance';
import BigNumber from 'bignumber.js';
import { useGetTokensPrice } from 'hooks/useTokenPrice';
import useVestingMethods from 'hooks/useVestingMethods';
import { observer } from 'mobx-react';
import useSequencerInfo from 'hooks/useSequencerInfo';
import { Tooltip } from '@mui/material';
import useCopyClipboard from 'hooks/useCopy';

const DataContainer = styled(DataWrapper)`
    height: auto;
    padding: 24px 20px;
`;

const BottomDataContainer = styled(DataContainer)`
    height: auto;
    background: linear-gradient(90deg, #2c160a 0%, #150701 100%);
    padding: 40px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    width: cala(100% - 20px);
    padding: 10px !important;
    `};
`;

const StrongText = styled(TYPE.main)`
    color: ${({ theme }) => theme.c.mainColor};
    font-size: 20 !important;
    font-weight: bold !important;
    margin-right: 2px !important;
`;

const DescText = styled(TYPE.main)`
    color: ${({ theme }) => theme.c.mainColor};
    font-size: 12px !important;
`;

const ItemsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
`;

const ItemWrapper = styled(ColumnCenter)`
    width: 25%;
    padding: 40px 18px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 50%;
    padding: 10px;
    `};
`;

const TextSubItem = styled.span`
    margin-right: 2px;
`;

const NFTBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 264px;
    width: 264px;
    background: linear-gradient(180deg, #10100f 0%, #3e3e3c 100%);
    border-radius: 24px;
    margin-top: 24px;
    margin-bottom: 32px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: auto;
    `};

    > img {
        height: 254px;
    }
`;

const InviteLinkBox = styled.div`
    position: relative;
    padding: 20px 80px 20px 20px;
    background: #000000;
    border-radius: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
    white-space: nowrap;
    width: 567px;
    z-index: 1;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};

    .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        left: 0;
        top: 0;
        background: linear-gradient(
            225deg,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.5) 100%,
            rgba(0, 0, 0, 0.3) 100%
        );
        border-radius: 20px;
        backdrop-filter: blur(2.4px);
    }

    .address-text {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const CopyIcon = styled.img`
    position: absolute;
    top: 50%;
    right: 28px;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    cursor: pointer;
`;

const InviteInfoItem = styled(Column)`
    width: 190px;
    height: 134px;
    justify-content: flex-start;
    padding-top: 24px;
`;

const StyledColumnCenter = styled(ColumnCenter)`
    margin-right: 72px;
    flex: none;
    width: 264px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-right: none;
    `};
`;

const OVERVIEWINFOS_INITIAL = [
    {
        key: 'eMetisBalance',
        amount: '0.000',
        symbol: 'eMetis',
        value: '$0.00',
        desc: 'Current eMetis Balance',
    },
    {
        key: 'seMetisBalance',
        amount: '0.000',
        symbol: 'seMetis',
        value: '$0.00',
        desc: 'Current seMetis Balance',
    },
    {
        key: 'enkiBalance',
        amount: '0.000',
        symbol: 'ENKI',
        value: '$0.00',
        desc: 'Current ENKI Balance',
    },
    {
        key: 'ENKIStaked',
        amount: '0.000',
        symbol: 'ENKI',
        value: '$0.00',
        desc: 'Staked ENKI',
    },
];

const INVITEINFOS_INITIAL = [
    {
        key: 'apr',
        value: '-%',
        desc: 'est.APR',
    },
    {
        key: 'staking points',
        value: 0,
        desc: 'Staking Points',
    },
    {
        key: 'referral points',
        value: 0,
        desc: 'Referral Points',
    },
];

function Portfolio() {
    const { eMetis, seMetis, enki } = useConstantToken();
    const { userStakeENKIBalance } = useVestingMethods();
    const { sequencerInfo } = useSequencerInfo();
    const { balance: eMetisBalance, getBalance: geteMetisBalance } =
        useTokenBalance(eMetis.address);

    const { balance: seMetisBalance, getBalance: getseMetisBalance } =
        useTokenBalance(seMetis.address);

    const { balance: enkiBalance, getBalance: geteEnkiBalance } =
        useTokenBalance(enki.address);

    const [overviewInfos, setOverviewInfos] = useState(OVERVIEWINFOS_INITIAL);

    const [inviteInfos, setInviteInfos] = useState(INVITEINFOS_INITIAL);

    const [showInviteLink, setShowInviteLink] = useState<boolean>(false); // [showInviteLink, setShowInvite]

    const send = useSend();
    const inviterNFTContract = useInviterNFT();
    const fetchTokenAddress = useMemo(() => {
        return [eMetis.address, seMetis.address, enki.address];
    }, [enki, seMetis, eMetis]);
    const tokenPrices = useGetTokensPrice(fetchTokenAddress);
    const [showCopy, setShowCopy] = useState(false);
    const [, setCopied] = useCopyClipboard();

    // const {
    //     getBalance,
    //     mint
    // } = useInviteNFTContract();

    useEffect(() => {
        setOverviewInfos([
            {
                ...OVERVIEWINFOS_INITIAL[0],
                amount: formatNumber(eMetisBalance),
                value: tokenPrices?.eMetis?.price
                    ? `$${formatNumber(
                          eMetisBalance.multipliedBy(tokenPrices?.eMetis.price),
                      )}`
                    : '',
            },
            {
                ...OVERVIEWINFOS_INITIAL[1],
                amount: formatNumber(seMetisBalance),
                value: tokenPrices?.seMetis?.price
                    ? `$${formatNumber(
                          seMetisBalance.multipliedBy(
                              tokenPrices?.seMetis.price,
                          ),
                      )}`
                    : '',
            },
            {
                ...OVERVIEWINFOS_INITIAL[2],
                amount: formatNumber(enkiBalance),
                value: tokenPrices?.enki?.price
                    ? `$${formatNumber(
                          enkiBalance.multipliedBy(tokenPrices?.enki.price),
                      )}`
                    : '',
            },
            {
                ...OVERVIEWINFOS_INITIAL[3],
                amount: formatNumber(userStakeENKIBalance),
                value: tokenPrices?.enki?.price
                    ? `$${formatNumber(
                          userStakeENKIBalance.multipliedBy(
                              tokenPrices?.enki.price,
                          ),
                      )}`
                    : '',
            },
        ]);
    }, [
        eMetisBalance,
        seMetisBalance,
        enkiBalance,
        tokenPrices,
        userStakeENKIBalance,
    ]);

    useEffect(() => {
        setInviteInfos([
            {
                key: 'referral_points',
                value: user.referrals.points,
                desc: 'Referral Points',
            },
            {
                key: 'referrals',
                value: user.referrals.referrals,
                desc: 'Referrals',
            },
            {
                key: 'total_staked',
                value: user.referrals.stakedByInvitees,
                desc: 'Total staked Metis by invitees',
            },
        ]);
    }, [user.referrals]);

    const inviteLink = useMemo(() => {
        if (user.address) {
            return `${window.location.origin}/mint?code=${compressAddress(
                user.address || ADDRESS_ZERO,
            )}`;
        } else {
            return `${window.location.origin}/mint`;
        }
    }, [user.address]);

    useEffect(() => {
        if (!inviterNFTContract || !user.address || !user.connected) {
            setShowInviteLink(false);
            return;
        }
        inviterNFTContract.balanceOf(user.address).then((res: BigNumber) => {
            setShowInviteLink(res.gt(0));
        });
    }, [user.connected, user.address, inviterNFTContract]);

    async function onMintNFTSuccess() {
        if (!user.address || !inviterNFTContract) return;
        const res: BigNumber = await inviterNFTContract.balanceOf(user.address);
        if (res.gt(0)) {
            setShowInviteLink(true);
        }
    }

    async function mintNFT() {
        if (!inviterNFTContract) return;

        await send({
            contract: inviterNFTContract,
            method: 'mint',
            params: [],
            pendingText: `Minting NFT`,
            summary: `Mint inviter NFT`,
            onSuccess: onMintNFTSuccess,
        });
    }

    function copylink() {
        setCopied(inviteLink);
        setShowCopy(true);
        setTimeout(() => {
            setShowCopy(false);
        }, 1000);
    }

    return (
        <PageWrapper>
            <ContentTitle>OVERVIEW</ContentTitle>

            <DataContainer style={{ marginTop: 24 }}>
                <Column>
                    <ItemsWrapper>
                        {overviewInfos.map((info) => {
                            return (
                                <ItemWrapper key={info.key}>
                                    <StrongText>
                                        <TextSubItem>{info.amount}</TextSubItem>
                                        <TextSubItem>{info.symbol}</TextSubItem>
                                        <TextSubItem style={{ fontSize: 12 }}>
                                            {info.value}
                                        </TextSubItem>
                                    </StrongText>
                                    <DescText>{info.desc}</DescText>
                                </ItemWrapper>
                            );
                        })}
                    </ItemsWrapper>

                    <TYPE.main
                        color="white01"
                        fontSize={16}
                        fontWeight={'bold'}
                        marginTop={20}
                        marginBottom={20}
                    >
                        Staking Rewards
                    </TYPE.main>

                    <Row>
                        <ItemWrapper>
                            <StrongText>
                                <TextSubItem>
                                    {sequencerInfo?.apr.toFixed(2) || '-'}%
                                </TextSubItem>
                            </StrongText>
                            <DescText>est.APR</DescText>
                        </ItemWrapper>
                        <ItemWrapper>
                            <StrongText>
                                <TextSubItem
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    {formatPoints(user.stakingPoints) || '-'}
                                </TextSubItem>
                            </StrongText>
                            <DescText>Staking Points</DescText>
                        </ItemWrapper>
                        <ItemWrapper>
                            <StrongText>
                                <TextSubItem
                                    style={{ textTransform: 'uppercase' }}
                                >
                                    {formatPoints(user.referrals.points) || '-'}
                                </TextSubItem>
                            </StrongText>
                            <DescText>Referral Points</DescText>
                        </ItemWrapper>
                    </Row>
                </Column>
            </DataContainer>

            <ContentTitle>REFERRAL</ContentTitle>

            <BottomDataContainer>
                {true && (
                    <ComingSoonWrapper style={{ left: 0, bottom: 0 }}>
                        <ComingSoonImg
                            src={require('assets/images/ic_comingsoon.png')}
                        />
                    </ComingSoonWrapper>
                )}
                <StyledColumnCenter>
                    <TYPE.main
                        color="mainColor"
                        fontSize={16}
                        fontWeight={'bold'}
                        textAlign={'center'}
                    >
                        Inviter NFT
                    </TYPE.main>

                    <NFTBox>
                        <img src={inviterNFTImg} />
                    </NFTBox>

                    {!showInviteLink && (
                        <>
                            <Button type="primary" auth onClick={mintNFT}>
                                Mint First
                            </Button>

                            <TYPE.main
                                color="mainColor"
                                fontSize={14}
                                textAlign={'center'}
                                marginTop={16}
                            >
                                You need to have at least 1 eMetis or seMetis in
                                your wallet to mint an inviter NFT.
                            </TYPE.main>
                        </>
                    )}
                </StyledColumnCenter>

                <Column style={{ flex: 1, width: '100%' }}>
                    <TYPE.main
                        color="white01"
                        fontWeight={'bold'}
                        fontSize={16}
                    >
                        Referral Link
                    </TYPE.main>

                    <InviteLinkBox>
                        <TYPE.main
                            color="white01"
                            fontSize={14}
                            className="address-text"
                        >
                            {inviteLink}
                        </TYPE.main>
                        <Tooltip
                            // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                            sx={{ zIndex: 1200 }}
                            open={showCopy}
                            title="Copied"
                            placement="top"
                            arrow
                        >
                            <CopyIcon src={copyLinkImg} onClick={copylink} />
                        </Tooltip>

                        {!showInviteLink && <div className="mask" />}
                    </InviteLinkBox>

                    <TYPE.main
                        color="mainColor"
                        fontSize={14}
                        marginBottom={40}
                    >
                        Anyone who stakes(at least 0.1 Metis) using this link
                        will get points for you. <br />
                        The points are calculated as follows: <br />
                        Points = 100 * Total Referrals + 200 * Total Staked
                        Metis by Invitees.
                    </TYPE.main>

                    <Row>
                        {inviteInfos.map((info) => {
                            return (
                                <InviteInfoItem key={info.key}>
                                    <TYPE.main
                                        color="mainColor"
                                        fontSize={40}
                                        fontWeight={'bold'}
                                    >
                                        {info.value}
                                    </TYPE.main>

                                    <TYPE.main color="mainColor" fontSize={14}>
                                        {info.desc}
                                    </TYPE.main>
                                </InviteInfoItem>
                            );
                        })}
                    </Row>
                </Column>
            </BottomDataContainer>
        </PageWrapper>
    );
}
export default observer(Portfolio);
